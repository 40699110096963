import React, { useContext, useEffect, useState } from "react";

import { useLoader } from "hooks";
import { StateContext } from "reducer/constants";
import { EmptyLoanIcon } from "ui-kit/images/icons";
import { LoanDataType } from "utils/types";
import { getName, getSupportedTokensAddressesNoSU, SupportedTokensType } from "utils/currency";
import { SuManagerFactory, SuOracleAggregatorFactory, SuVaultFactory } from "utils/api";
import { BN_1E18, toHRNumber } from "utils/bigNumber";
import { LoansTable } from "./supportComponents/LoansTable";
import { EmptyLoans } from "./supportComponents/EmptyLoans";

import "./styles.scss";

export const PageLoan = () => {
    const [data, setData] = useState<Record<string, LoanDataType | undefined>>({});
    const { isLoading, start: startLoader, stop: stopLoader } = useLoader(true);
    const { currentAddress, chainId } = useContext(StateContext);

    const getTokenData = async (tokenAddress: string) => {
        const tokenName = getName(tokenAddress, chainId) as SupportedTokensType;
        const deposited = await SuVaultFactory.getDeposited(tokenAddress);
        const debt = await SuVaultFactory.getDebt(tokenAddress);
        const price = await SuOracleAggregatorFactory.getFiatPrice1e18(tokenAddress);
        const ltv = await SuManagerFactory.getLTVE18(tokenName, chainId);

        if (deposited && debt && (!deposited?.isZero() || !debt?.isZero()) && price) {
            setData((oldData) => ({
                ...oldData,
                [tokenAddress]: {
                    tokenName: getName(tokenAddress, chainId) as SupportedTokensType,
                    debt: toHRNumber(debt, 18),
                    collateralValue: toHRNumber(deposited.multipliedBy(price).div(BN_1E18), 18),
                    ltv: toHRNumber(ltv.multipliedBy(100), 18),
                },
            }));
        } else {
            setData((oldData) => ({
                ...oldData,
                [tokenAddress]: undefined,
            }));
        }
    };

    useEffect(() => {
        if (currentAddress && chainId) {
            startLoader();
            Promise.all(getSupportedTokensAddressesNoSU(chainId).map(getTokenData));
        }
    }, [currentAddress, chainId, startLoader]);

    const tableData = Object.values(data)
        .filter((v) => v?.debt)
        .sort((a, b) => (b?.debt ?? 0) - (a?.debt ?? 0)) as LoanDataType[];

    useEffect(() => {
        const supportedTokensCount = chainId ? getSupportedTokensAddressesNoSU(chainId).length : 0;
        if (
            Object.keys(data).length > 0 &&
            (tableData.length > 1 || Object.keys(data).length === supportedTokensCount)
        ) {
            stopLoader();
        }
    }, [chainId, data, stopLoader, tableData]);

    if (isLoading) {
        return (
            <div className="loan">
                <div className="empty-loans">
                    <div className="empty-loans__icon">
                        <EmptyLoanIcon />
                    </div>
                    <div className="empty-loans__title">Loading...</div>
                </div>
            </div>
        );
    }

    if (tableData.length === 0) {
        return (
            <div className="loan">
                <EmptyLoans />
            </div>
        );
    }

    return (
        <div className="loan">
            <LoansTable data={tableData} />
        </div>
    );
};
