import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import BigNumber from "bignumber.js";

import { StateContext } from "reducer/constants";
import { CloseIcon } from "ui-kit/images/icons";
import { getTokenGroup, SupportedTokensType } from "utils/currency";
import { CommonFactory } from "utils/api";
import { BoostModalItem } from "../BoostModalItem";

import "./styles.scss";

interface BoostModalProps {
    onStake: (name: SupportedTokensType) => void;
    onClose: () => void;
    visible: boolean;
    tokenName: SupportedTokensType;
}

interface BoostDataType {
    name: SupportedTokensType;
    apr: number;
    address: string;
    balance?: BigNumber;
}

export const BoostModal = ({ onClose, visible, tokenName, onStake }: BoostModalProps) => {
    const { chainId, currentAddress } = useContext(StateContext);
    const [data, setData] = useState<BoostDataType[]>();

    const updateData = async () => {
        const group = getTokenGroup(tokenName, chainId);
        const newData = await Promise.all(
            group.map(async (v, i) => ({
                name: v.symbol as SupportedTokensType,
                address: v.address,
                apr: (v.decimals - 5) / (i + 1),
                balance: await CommonFactory.balance(v.address),
            }))
        );
        setData(newData);
    };

    useEffect(() => {
        updateData();
    }, [tokenName, chainId, currentAddress]);

    return (
        <Modal isOpen={visible} onRequestClose={onClose} className="boost-modal" overlayClassName="boost-modal-overlay">
            <div className="boost-modal__header">
                <div>Increase deposit yield for {tokenName}</div>
                <CloseIcon className="boost-modal__close" onClick={onClose} />
            </div>
            <div className="boost-modal__content">
                {data?.map((v) => (
                    <BoostModalItem
                        key={v.name}
                        {...v}
                        onStake={() => onStake(v.name)}
                        disabled={v.name === tokenName || v.balance?.eq(0)}
                    />
                ))}
            </div>
        </Modal>
    );
};
