import React, { useContext, useMemo, useState } from "react";

import { useGlobalUpdate, useLoader } from "hooks";
import { ButtonGradient } from "ui-kit";
import { TokenInput } from "components/TokenInput";
import { DepositBooster } from "pages/PageBorrow/supportComponents/DepositBooster";
import { StateContext } from "reducer/constants";
import { SupportedTokensType } from "utils/currency";
import { SuManagerFactory } from "utils/api";
import { addErrorNotification, addSuccessNotification } from "utils/notification";
import { boosterLockupOptions } from "utils/lockup";

import "./styles.scss";

interface DepositContentProps {
    token: SupportedTokensType;
    onClose: () => void;
}

export const DepositContent = ({ token, onClose }: DepositContentProps) => {
    const { chainId } = useContext(StateContext);
    const [value, setValue] = useState<number>();
    const [balance, setBalance] = useState(0);
    const { isLoading, start: startLoader, stop: stopLoader } = useLoader();
    const { update } = useGlobalUpdate();
    const [boosterLockupValue, setBoosterLockupValue] = useState(boosterLockupOptions[boosterLockupOptions.length - 1]);

    const handleBoosterLockupValueChange = (lockupId: number) => {
        const newValue = boosterLockupOptions.find((v) => v.id === lockupId);
        if (newValue) {
            setBoosterLockupValue(newValue);
        }
    };

    const handleValueChange = (newValue?: number) => {
        setValue(newValue);
    };

    const handleAction = async () => {
        if (value) {
            try {
                startLoader();
                await SuManagerFactory.deposit(token, value, boosterLockupValue.id, chainId);
                addSuccessNotification("Deposit finished successfully");
                update();
                stopLoader();
                setValue(0);
                onClose();
            } catch (e) {
                addErrorNotification("Deposit error", e.message);
                stopLoader();
            }
        }
    };

    const hasEnoughBalance = !value || value <= balance;
    const btnText = useMemo(() => {
        if (isLoading) {
            return "Loading...";
        }
        if (!hasEnoughBalance) {
            return "Insufficient balance";
        }
        return "DEPOSIT";
    }, [isLoading, hasEnoughBalance]);

    return (
        <div className="deposit-content">
            <TokenInput
                onBalanceChange={(newBalance) => setBalance(newBalance)}
                className="deposit-content__token-input"
                tokenName={token}
                value={value}
                onValueChange={handleValueChange}
                isTokenFixed
            />
            <DepositBooster
                className="deposit-content__lockup"
                values={boosterLockupOptions}
                value={boosterLockupValue}
                onChange={handleBoosterLockupValueChange}
            />
            <ButtonGradient
                className="deposit-content__button"
                onClick={handleAction}
                disabled={!value || !hasEnoughBalance || isLoading}
                loading={isLoading}
            >
                {btnText}
            </ButtonGradient>
        </div>
    );
};
