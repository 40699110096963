import { Contract } from "web3-eth-contract";
import Web3 from "web3";
import BigNumber from "bignumber.js";

import CONTRACT_ERC20 from "contracts/ERC20.json";
import SuVault from "submodule-contract-artifacts/goerli/SuVault.json";
import StablePro from "submodule-contract-artifacts/goerli/StablePro.json";

import { BORROW_CURRENCY, getAddress, getDecimals, SupportedTokensType } from "./currency";
import { fromHRNumber } from "./bigNumber";
import { LockupDataType, PossibleLockupPeriodsType } from "./types";

let currentAddress: string;
export const setUtilsCurrentAddress = (newAddress: string) => {
    currentAddress = newAddress;
};

let web3: Web3;
export const setUtilsWeb3 = (newWeb3: Web3) => {
    web3 = newWeb3;
};

type ContractsType =
    | "SuManagerContract"
    | "SuVaultContract"
    | "CollateralRegistryContract"
    | "SuOracleAggregator"
    | "RewardContract";

export const contracts: Record<ContractsType, Contract | undefined> = {
    SuManagerContract: undefined,
    SuVaultContract: undefined,
    CollateralRegistryContract: undefined,
    SuOracleAggregator: undefined,
    RewardContract: undefined,
};

export const setSuManagerContract = (newContract: Contract) => {
    contracts.SuManagerContract = newContract;
};

export const setSuVaultContract = (newContract: Contract) => {
    contracts.SuVaultContract = newContract;
};

export const setCollateralRegistryContract = (newContract: Contract) => {
    contracts.CollateralRegistryContract = newContract;
};

export const setOracleAggregator = (newContract: Contract) => {
    contracts.SuOracleAggregator = newContract;
};

export const setRewardContract = (newContract: Contract) => {
    contracts.RewardContract = newContract;
};

const tryToRunLocal = async (command: any, options: Record<string, any> = {}) => {
    await command.estimateGas({ from: currentAddress, ...options });
    return command;
};

export const SuOracleAggregatorFactory = {
    getFiatPrice1e18: async (tokenAddress?: string) => {
        if (contracts.SuOracleAggregator && tokenAddress) {
            return new BigNumber(await contracts.SuOracleAggregator.methods.getFiatPrice1e18(tokenAddress).call());
        }

        return undefined;
    },
};

export const SuManagerFactory = {
    borrow: async (
        currency: SupportedTokensType,
        amount: number,
        stablecoinAmount: number,
        boosterLockupValue: number,
        chainId: number | undefined
    ) => {
        if (contracts.SuManagerContract && chainId) {
            let amountBN = "0";
            let stablecoinAmountBN = "0";

            if (amount) {
                const tokenDecimals = getDecimals(currency, chainId);
                amountBN = fromHRNumber(amount, tokenDecimals ?? 0).toString(10);
            }

            if (stablecoinAmount) {
                const stablecoinDecimals = getDecimals(BORROW_CURRENCY, chainId);
                stablecoinAmountBN = fromHRNumber(stablecoinAmount, stablecoinDecimals ?? 0).toString(10);
            }

            const command = await tryToRunLocal(
                contracts.SuManagerContract.methods.join(
                    getAddress(currency, chainId),
                    amountBN,
                    stablecoinAmountBN,
                    boosterLockupValue
                )
            );
            return command.send({ from: currentAddress });
        }
    },
    getLiquidationPrice: async (currency: SupportedTokensType | undefined, chainId: number | undefined) => {
        if (contracts.SuManagerContract && currentAddress && currency && chainId) {
            return new BigNumber(
                await contracts.SuManagerContract.methods
                    .liquidationPriceE18(getAddress(currency, chainId), currentAddress)
                    .call()
            );
        }
    },
    getLiquidationPriceByAmount: async (
        currency: SupportedTokensType | undefined,
        chainId: number | undefined,
        additionalCollateralAmount: BigNumber,
        additionalStablecoinAmount: BigNumber
    ) => {
        if (contracts.SuManagerContract && currentAddress && currency && chainId) {
            return new BigNumber(
                await contracts.SuManagerContract.methods
                    .liquidationPriceByAmount(
                        getAddress(currency, chainId),
                        currentAddress,
                        additionalCollateralAmount.toString(10),
                        additionalStablecoinAmount.toString(10)
                    )
                    .call()
            );
        }
    },
    // TODO unify usage of tokenAddress and currency
    getLiquidationRatio: async (tokenAddress?: string) => {
        if (contracts.SuManagerContract && tokenAddress) {
            return new BigNumber(await contracts.SuManagerContract.methods.liquidationRatioE18(tokenAddress).call());
        }
    },
    getInitialCollateralRatio: async (tokenAddress?: string) => {
        if (contracts.SuManagerContract && tokenAddress) {
            return new BigNumber(
                await contracts.SuManagerContract.methods.initialCollateralRatioE18(tokenAddress).call()
            );
        }
    },
    deposit: async (
        currency: SupportedTokensType,
        amount: number,
        boosterLockupValue: number,
        chainId: number | undefined
    ) => SuManagerFactory.borrow(currency, amount, 0, boosterLockupValue, chainId),
    exit: async (
        currency: SupportedTokensType,
        assetAmountBN: BigNumber,
        stablecoinAmountBN: BigNumber,
        chainId: number | undefined
    ) => {
        if (contracts.SuManagerContract && chainId) {
            const command = await tryToRunLocal(
                contracts.SuManagerContract.methods.exit(
                    getAddress(currency, chainId),
                    assetAmountBN,
                    stablecoinAmountBN
                )
            );
            return command.send({ from: currentAddress });
        }
    },
    withdraw: async (currency: SupportedTokensType, amountBN: BigNumber, chainId: number | undefined) =>
        SuManagerFactory.exit(currency, amountBN, new BigNumber(0), chainId),
    repay: async (currency: SupportedTokensType, amountBN: BigNumber, chainId: number | undefined) =>
        SuManagerFactory.exit(currency, new BigNumber(0), amountBN, chainId),
    getAvailableToBorrow: async (currency: SupportedTokensType, chainId: number | undefined) => {
        if (contracts.SuManagerContract && currentAddress && chainId) {
            return new BigNumber(
                await contracts.SuManagerContract.methods
                    .getAvailableToBorrowE18(getAddress(currency, chainId), currentAddress)
                    .call()
            );
        }

        return new BigNumber(0);
    },
    getAvailableToWithdraw: async (currency: SupportedTokensType, chainId: number | undefined) => {
        if (contracts.SuManagerContract && currentAddress && chainId) {
            return new BigNumber(
                await contracts.SuManagerContract.methods
                    .getAvailableToWithdrawE18(getAddress(currency, chainId), currentAddress)
                    .call()
            );
        }

        return new BigNumber(0);
    },
    getLTVE18: async (currency: SupportedTokensType, chainId: number | undefined, user = currentAddress) => {
        if (contracts.SuManagerContract && user && chainId) {
            return new BigNumber(
                await contracts.SuManagerContract.methods.getLTVE18(getAddress(currency, chainId), user).call()
            );
        }

        return new BigNumber(0);
    },
};

export const SuVaultFactory = {
    getDeposited: async (asset?: string, user = currentAddress) => {
        if (contracts.SuVaultContract && user && asset) {
            return new BigNumber(await contracts.SuVaultContract.methods.collateralsEDecimal(asset, user).call());
        }
        return undefined;
    },
    getTotalDebtE18: async (asset?: string) => {
        if (contracts.SuVaultContract && asset && currentAddress && asset) {
            return new BigNumber(await contracts.SuVaultContract.methods.getTotalDebtE18(asset, currentAddress).call());
        }
        return undefined;
    },
    getDebt: async (asset?: string, user = currentAddress) => {
        if (contracts.SuVaultContract && user && asset) {
            return new BigNumber(await contracts.SuVaultContract.methods.debtsE18(asset, user).call());
        }
        return undefined;
    },
    calculateFeeE18: async (asset?: string, user = currentAddress) => {
        if (contracts.SuVaultContract && user && asset) {
            const debts = await SuVaultFactory.getDebt(asset);
            return new BigNumber(
                await contracts.SuVaultContract.methods.calculateFeeE18(asset, user, debts?.toString(10)).call()
            );
        }
        return undefined;
    },
    getTokenDebts: async (asset?: string) => {
        let suVaultContract = contracts.SuVaultContract;
        if (!suVaultContract) {
            const newWeb3 = new Web3(Web3.givenProvider);
            suVaultContract = new newWeb3.eth.Contract(SuVault.abi as any, SuVault.address);
        }

        if (suVaultContract && asset) {
            return new BigNumber(await suVaultContract.methods.tokenDebtsE18(asset).call());
        }
        return new BigNumber(0);
    },
    getTokenDebtsLimit: async (asset?: string) => {
        if (contracts.SuVaultContract && asset) {
            return new BigNumber(await contracts.SuVaultContract.methods.tokenDebtLimitE18(asset).call());
        }
        return new BigNumber(0);
    },
    getProtocolStabilityFee: async (asset?: string) => {
        if (contracts.SuVaultContract && asset) {
            return new BigNumber(await contracts.SuVaultContract.methods.protocolStabilityFeeE18(asset).call());
        }
        return new BigNumber(0);
    },
    getFoundation: async () => {
        if (contracts.SuVaultContract) {
            return contracts.SuVaultContract.methods.foundation().call();
        }
        return undefined;
    },
    getBorrowInterestApr: async (tokenAddress?: string) => {
        if (contracts.SuVaultContract && tokenAddress && currentAddress) {
            try {
                return new BigNumber(
                    await contracts.SuVaultContract.methods.stabilityFeeE18(tokenAddress, currentAddress).call()
                );
            } catch (e) {
                return undefined;
            }
        }

        return undefined;
    },
    // getBorrowed: async (currency: SupportedTokensType) => {
    //     if (contracts.Vault) {
    //         const borrowed = await SuVaultFactory.getTotalDebt(getAddress(currency));
    //         if (currency === "ETH") {
    //             return +web3.utils.fromWei(`${borrowed}`);
    //         }
    //         const decimals = await CommonFactory.decimals(CommonFactory.createCurrencyContract(currency));
    //         return toHRNumber(new BigNumber(borrowed), decimals);
    //     }
    //     return 0;
    // },
};

export const RewardContractFactory = {
    getPoolApr: async (tokenAddress?: string) => {
        if (contracts.RewardContract && tokenAddress) {
            try {
                try {
                    const apr = await contracts.RewardContract.methods.getPoolApr(tokenAddress).call();
                    return new BigNumber(apr);
                    // eslint-disable-next-line no-empty
                } catch (e) {}

                return new BigNumber(0);
            } catch (e) {
                return undefined;
            }
        }

        return undefined;
    },
    getDepositLockup: async (tokenAddress?: string) => {
        if (contracts.RewardContract && tokenAddress && currentAddress) {
            const userInfo = await contracts.RewardContract.methods.userInfo(tokenAddress, currentAddress).call();
            if (userInfo) {
                return {
                    lockupPeriodSeconds: +userInfo.lockupPeriodSeconds,
                    lockupStartTimestamp: +userInfo.lockupStartTimestamp,
                    multiplicator1e18: new BigNumber(userInfo.multiplicator1e18),
                } as LockupDataType;
            }
        }
        return undefined;
    },
    getPossibleLockupPeriods: async () => {
        if (contracts.RewardContract) {
            try {
                const possibleLockupPeriods = await contracts.RewardContract.methods
                    .getPossibleLockupPeriodsSeconds()
                    .call();

                return possibleLockupPeriods.map((v: Record<string, number>) => ({
                    lockupPeriodSeconds: +v.lockupPeriodSeconds,
                    multiplicator1e18: new BigNumber(v.multiplicator1e18),
                })) as PossibleLockupPeriodsType;
            } catch (e) {
                return [];
            }
        }

        return [];
    },
    getPendingRewards: async (tokenAddress?: string) => {
        if (contracts.RewardContract && tokenAddress && currentAddress) {
            return new BigNumber(
                await contracts.RewardContract.methods.pendingSushi(tokenAddress, currentAddress).call()
            );
        }
        return undefined;
    },
    harvest: async (tokenAddress?: string) => {
        if (contracts.RewardContract && tokenAddress && currentAddress) {
            const currentLockup = await RewardContractFactory.getDepositLockup(tokenAddress);
            const command = await tryToRunLocal(
                contracts.RewardContract.methods.harvest(
                    tokenAddress,
                    currentAddress,
                    currentLockup?.lockupPeriodSeconds
                )
            );
            return command.send({ from: currentAddress });
        }
        return undefined;
    },
    getHarvestLockup: async (tokenAddress: string) => {
        if (contracts.RewardContract && currentAddress) {
            try {
                const timestamp = await contracts.RewardContract.methods
                    .getHarvestLockupEndTimestamp(tokenAddress, currentAddress)
                    .call();
                return new Date(timestamp * 1000).toLocaleString();
                // eslint-disable-next-line no-empty
            } catch (e) {}
        }
        return "-";
    },
};

export const CommonFactory = {
    createCurrencyContract: (address: string) => {
        const newWeb3 = web3 ?? new Web3(Web3.givenProvider);
        if (newWeb3 && address) {
            return new newWeb3.eth.Contract(CONTRACT_ERC20 as any, address);
        }
        return undefined;
    },
    approve: async (address?: string, amount?: BigNumber) => {
        if (address) {
            const tokenContract = CommonFactory.createCurrencyContract(address);
            const command = await tryToRunLocal(
                tokenContract?.methods.approve(
                    SuVault.address,
                    amount ?? "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
                )
            );
            return command.send({ from: currentAddress });
        }
    },
    allowance: async (tokenName: SupportedTokensType, address = currentAddress, chainId: number | undefined) => {
        if (!address || !chainId) {
            return new BigNumber(0);
        }
        const tokenContract = CommonFactory.createCurrencyContract(getAddress(tokenName, chainId) as string);
        return new BigNumber(await tokenContract?.methods.allowance(address, SuVault.address).call());
    },
    // TODO: fix tokenName
    totalSupply: async (tokenName: SupportedTokensType, chainId: number | undefined) => {
        const address = getAddress(BORROW_CURRENCY, chainId);
        if (address) {
            const tokenContract = CommonFactory.createCurrencyContract(address);
            return new BigNumber(await tokenContract?.methods.totalSupply().call());
        }
        return new BigNumber(0);
    },
    balance: async (tokenAddress?: string) => {
        if (!web3 || !tokenAddress || !currentAddress) {
            return new BigNumber(0);
        }

        const tokenContract = new web3.eth.Contract(CONTRACT_ERC20 as any, tokenAddress);
        return new BigNumber(await tokenContract.methods.balanceOf(currentAddress).call());
    },
    StableProYieldAPR: async (chainId: number | undefined) => {
        const address = getAddress(BORROW_CURRENCY, chainId);
        if (address) {
            const newWeb3 = web3 ?? new Web3(Web3.givenProvider);
            const tokenContract = new newWeb3.eth.Contract(StablePro.abi as any, address);
            return new BigNumber(await tokenContract?.methods.getYieldAPR().call());
        }
        return new BigNumber(0);
    },
};
