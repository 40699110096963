import React from "react";
// import { useHistory } from "react-router-dom";

import { CloseIcon } from "ui-kit/images/icons";
import { SupportedTokensType } from "utils/currency";
import { MergedIcons } from "../MergedIcons";

import "./styles.scss";

interface DisclaimerProps {
    onClose: () => void;
    token1: SupportedTokensType;
    token2: SupportedTokensType;
}

export const Disclaimer = ({ onClose, token1, token2 }: DisclaimerProps) => {
    // const history = useHistory();

    // const handleDisclaimerClick = () => {
    //     history.push(`/earn/${token1}-${token2}`);
    // };

    const handleCloseClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        onClose();
    };

    return (
        <div className="disclaimer">
            <CloseIcon onClick={handleCloseClick} className="disclaimer__close" />
            <MergedIcons token1={token1} token2={token2} size="l" />
            <div className="disclaimer__title">
                {token1} - {token2} staking
            </div>
            <div className="disclaimer__description">Earn up to 16% APY</div>
        </div>
    );
};
