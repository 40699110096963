import React from "react";
import cn from "classnames";

import { GradientHref } from "ui-kit";

import "./styles.scss";

interface HeaderDropdownProps {
    onClose: () => void;
    visible: boolean;
}

const LinksDropdownContent = () => (
    <div className="header-dropdown links-dropdown">
        <a href="https://feat-usdpro--suui.netlify.app" className="header-dropdown__line" rel="noreferrer">
            USDPro
        </a>
        <a href="https://testnet.stableunit.org/" className="header-dropdown__line" rel="noreferrer">
            EURPro
        </a>
    </div>
);

export const LinksDropdown = ({ visible, onClose }: HeaderDropdownProps) => (
    <div
        className={cn("header-dropdown-container", {
            "header-dropdown-container--visible": visible,
        })}
        onClick={onClose}
    >
        <LinksDropdownContent />
    </div>
);
