import React from "react";

import { GradientHref } from "ui-kit";

import { InfoContainer } from "../InfoContainer";

import "./styles.scss";

export const TransactionsInfo = () => (
    <InfoContainer title="Transactions" className="transactions-info">
        <div className="transactions-info__value">22.5M</div>
        <div className="transactions-info__settings">
            <GradientHref>Last 24 hours</GradientHref>
        </div>
    </InfoContainer>
);
