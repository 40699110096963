import React from "react";

import "./styles.scss";

export const Footer = () => {
    return (
        <div className="footer">
            <div>Beta version. Use at your own risk</div>
            <div className="footer__version">
                <a
                    href={`https://github.com/StableUnit/suUI/releases/tag/v${process.env.REACT_APP_VERSION}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    (v{process.env.REACT_APP_VERSION})
                </a>
            </div>
        </div>
    );
};
