import React, { useContext, useMemo, useState } from "react";
import BigNumber from "bignumber.js";
import { useParams } from "react-router-dom";

import { Actions } from "reducer";
import { useAPR, useDeposited, useGlobalUpdate, useLoader } from "hooks";
import { ButtonGradient } from "ui-kit";
import { TokenInput } from "components/TokenInput";
import { DataSliderContainer } from "components/DataSliderContainer";
import { InfoLine } from "components/InfoLine";
import { useEnoughAllowance } from "hooks/parameters/useEnoughAllowance";
import { DispatchContext, StateContext } from "reducer/constants";
import { getAddress, SupportedTokensType } from "utils/currency";
import { CommonFactory, SuManagerFactory } from "utils/api";
import { addErrorNotification, addSuccessNotification } from "utils/notification";
import { boosterLockupOptions } from "utils/lockup";
import { toHRNumber } from "utils/bigNumber";

import { DepositBooster } from "../../../PageBorrow/supportComponents/DepositBooster";
import "./styles.scss";

export const DepositInfo = () => {
    const { id } = useParams();
    const { chainId, possibleLockupPeriods } = useContext(StateContext);
    const dispatch = useContext(DispatchContext);
    const tokenName = id as SupportedTokensType;
    const [tokenNameSelected, setTokenNameSelected] = useState(tokenName);
    const [tokenValue, setTokenValue] = useState<number>();
    const { deposited } = useDeposited(tokenName);
    const { rewardBN } = useAPR(tokenName);
    const { isLoading, start: startLoader, stop: stopLoader } = useLoader();
    const { update } = useGlobalUpdate();
    const [balance, setBalance] = useState(0);
    const { isEnoughAllowance, setIsEnoughAllowance } = useEnoughAllowance(tokenName);
    const { isLoading: isApproveLoading, start: startApproveLoader, stop: stopApproveLoader } = useLoader();
    const [boosterLockupValue, setBoosterLockupValue] = useState(boosterLockupOptions[boosterLockupOptions.length - 1]);

    const handleTokenNameChange = (newTokenName: SupportedTokensType) => {
        setTokenNameSelected(newTokenName);
    };

    const handleTokenValueChange = (newTokenValue?: number) => {
        setTokenValue(newTokenValue);
    };

    const handleDeposit = async () => {
        if (tokenValue && chainId) {
            try {
                startLoader();
                await SuManagerFactory.deposit(tokenName, tokenValue, boosterLockupValue.id, chainId);
                addSuccessNotification("Deposit finished successfully");
                update();
                stopLoader();
                setTokenValue(0);
            } catch (e) {
                addErrorNotification("Deposit error", e.message);
                stopLoader();
            }
        }
    };
    const hasEnoughBalance = !tokenValue || tokenValue <= balance;
    const btnText = useMemo(() => {
        if (isLoading) {
            return "Loading...";
        }
        if (!hasEnoughBalance) {
            return "Insufficient balance";
        }
        return "DEPOSIT";
    }, [isLoading, hasEnoughBalance]);

    const handleWithdraw = () => {
        dispatch({ type: Actions.SetActionModalTab, payload: "withdraw" });
        dispatch({ type: Actions.SetIsActionModalOpened, payload: true });
    };

    const handleApprove = async () => {
        if (chainId) {
            try {
                startApproveLoader();
                await CommonFactory.approve(getAddress(tokenName, chainId));
                addSuccessNotification("Approve finished successfully");
                setIsEnoughAllowance(true);
                stopApproveLoader();
            } catch (e) {
                stopApproveLoader();
                addErrorNotification("Approve error", e.message);
            }
        }
    };

    if (!tokenNameSelected) {
        return null;
    }

    const handleBoosterLockupValueChange = (lockupId: number) => {
        const newValue = boosterLockupOptions.find((v) => v.id === lockupId);
        if (newValue) {
            setBoosterLockupValue(newValue);
        }
    };

    const selectedMultiplicator =
        possibleLockupPeriods.find((v) => v.lockupPeriodSeconds === boosterLockupValue.id)?.multiplicator1e18 ??
        new BigNumber(0);

    const depositRewards = rewardBN
        ? toHRNumber(rewardBN.multipliedBy(100).multipliedBy(selectedMultiplicator), 18 + 18)
        : 0;

    return (
        <DataSliderContainer
            id={`deposit-${id?.toLowerCase()}`}
            className="deposit-info"
            title="Deposit Info"
            name={`${id} Deposit`}
            value={deposited}
            token={id as SupportedTokensType}
            buttonText="Withdraw"
            contentPadding={1}
            onClick={handleWithdraw}
        >
            <div className="deposit-info__deposit">
                <TokenInput
                    className="deposit-info__deposit__input"
                    tokenName={tokenNameSelected}
                    onTokenChange={handleTokenNameChange}
                    onValueChange={handleTokenValueChange}
                    onBalanceChange={(newBalance) => setBalance(newBalance)}
                />
                <InfoLine title="Deposit Rewards:" value={`${depositRewards.toFixed(2)}% APY`} color="green" />
                <DepositBooster
                    className="deposit-info__lockup"
                    values={boosterLockupOptions}
                    value={boosterLockupValue}
                    onChange={handleBoosterLockupValueChange}
                />
                {isEnoughAllowance ? (
                    <ButtonGradient
                        className="deposit-info__button"
                        disabled={!tokenValue || !hasEnoughBalance || isLoading}
                        onClick={handleDeposit}
                        loading={isLoading}
                    >
                        {btnText}
                    </ButtonGradient>
                ) : (
                    <ButtonGradient
                        className="deposit-info__button"
                        onClick={handleApprove}
                        disabled={isApproveLoading}
                        loading={isApproveLoading}
                    >
                        {isApproveLoading ? "Loading..." : "Approve"}
                    </ButtonGradient>
                )}
            </div>
        </DataSliderContainer>
    );
};
