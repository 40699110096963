import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import cn from "classnames";

import { Button, GradientHref } from "ui-kit";
import { SortIcon } from "ui-kit/images/icons";
import { DirectionType, EarnDataItemType } from "utils/types";
import { getTokenIcon } from "utils/currency";

import "./styles.scss";

interface AssetsTableType {
    data: EarnDataItemType[];
    needDeposits: boolean;
}

export const AssetsTable = ({ data, needDeposits }: AssetsTableType) => {
    const history = useHistory();

    const [currentData, setCurrentData] = useState(data);
    const [direction, setDirection] = useState({
        tokenName: undefined,
        apr: undefined,
        deposited: undefined,
        rewards: undefined,
    } as Record<keyof EarnDataItemType, DirectionType>);

    useEffect(() => {
        if (data) {
            setCurrentData(data);
        }
    }, [data]);

    const sortBy = (field: keyof EarnDataItemType) => () => {
        const filedDirection = direction[field];
        const result = [...currentData].sort((a, b) => {
            const multiplier = filedDirection === "asc" ? 1 : -1;
            return (a[field] ?? 0) > (b[field] ?? 0) ? multiplier : -multiplier;
        });
        setDirection({ ...direction, [field]: filedDirection === "asc" ? "desc" : "asc" });
        setCurrentData(result);
    };

    const handleOpenToken = (token: string) => () => {
        history.push(`/earn/${token}`);
    };

    return (
        <table className={cn("assets-table", { "assets-table--no-deposits": !needDeposits })}>
            <thead className="assets-table__header">
                <tr>
                    <td width={240}>
                        <div className="assets-table__header__cell" onClick={sortBy("tokenName")}>
                            ASSETS
                            <SortIcon />
                        </div>
                    </td>
                    <td width={140}>
                        <div className="assets-table__header__cell" onClick={sortBy("apr")}>
                            APY
                            <SortIcon />
                        </div>
                    </td>
                    {needDeposits ? (
                        <>
                            <td>
                                <div className="assets-table__header__cell" onClick={sortBy("deposited")}>
                                    Deposits
                                    <SortIcon />
                                </div>
                            </td>
                            <td align="right">
                                <div className="assets-table__header__cell" onClick={sortBy("rewards")}>
                                    Rewards
                                    <SortIcon />
                                </div>
                            </td>
                            <td />
                        </>
                    ) : (
                        <td />
                    )}
                </tr>
            </thead>
            <tbody className="assets-table__body">
                {currentData.map((item) => (
                    <tr
                        className="assets-table__body__line"
                        key={item.tokenName}
                        onClick={needDeposits ? handleOpenToken(item.tokenName) : undefined}
                    >
                        <td>
                            <div className="assets-table__body__line__icon">
                                {getTokenIcon(item.tokenName)}
                                <div className="assets-table__body__line__icon__text">{item.tokenName}</div>
                            </div>
                        </td>
                        <td className="assets-table__body__apy">{item.apr.toFixed(1)}% APY</td>
                        {needDeposits ? (
                            <>
                                <td>{item.deposited?.toLocaleString()}</td>
                                <td className="assets-table__body__rewards">
                                    <GradientHref href={`/earn/${item.tokenName}`}>
                                        {item.rewards?.toLocaleString()} suDAO
                                    </GradientHref>
                                </td>
                            </>
                        ) : (
                            <td>
                                <div className="assets-table__body__line__button">
                                    <Button
                                        id={`deposit-${item.tokenName.toLowerCase()}`}
                                        width={80}
                                        padding="6px 17px"
                                        onClick={handleOpenToken(item.tokenName)}
                                    >
                                        Deposit
                                    </Button>
                                </div>
                            </td>
                        )}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
