import { useContext } from "react";

import { StateContext } from "reducer/constants";
import { RewardContractFactory } from "utils/api";
import { getAddress, SupportedTokensType } from "utils/currency";
import { toHRNumber } from "utils/bigNumber";

import { useParameter } from "./useParameter";

export const useAPR = (currency: SupportedTokensType) => {
    const { chainId } = useContext(StateContext);
    const tokenAddress = getAddress(currency, chainId);
    const rewardBN = useParameter(
        `apr ${currency}`,
        () => RewardContractFactory.getPoolApr(tokenAddress),
        tokenAddress
    );

    return {
        rewardBN,
        reward: rewardBN ? toHRNumber(rewardBN.multipliedBy(100), 18) : 0,
    };
};
