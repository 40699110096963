import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";

import { useSuAPR } from "hooks";
import { GradientHref, LoaderLine } from "ui-kit";
import { StateContext } from "reducer/constants";
import tokenLogo from "ui-kit/images/currency/USDPro.svg";
import { BORROW_CURRENCY_NAME } from "utils/currency";
import { toHRNumber } from "utils/bigNumber";
import { LinksDropdown } from "../LinksDropdown";

import "./styles.scss";

export const StableProInfo = () => {
    const { currentAddress, StableProBalance } = useContext(StateContext);
    const { suAPR } = useSuAPR();

    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const openDropdown = () => setIsDropdownVisible(true);
    const closeDropdown = () => setIsDropdownVisible(false);

    return (
        <div className="usd-pro-info">
            <NavLink to="/">
                <img src={tokenLogo} width={48} height={48} />
            </NavLink>
            <div className="usd-pro-info__info">
                {currentAddress ? (
                    <>
                        <GradientHref className="usd-pro-info__info__title" onClick={openDropdown}>
                            {BORROW_CURRENCY_NAME}
                        </GradientHref>
                        <div className="usd-pro-info__info__description">
                            {toHRNumber(StableProBalance, 18, 8).toLocaleString("en-us", {
                                maximumFractionDigits: 6,
                                minimumFractionDigits: 6,
                            })}
                        </div>
                    </>
                ) : (
                    <div className="usd-pro-info__info__description">
                        {suAPR !== undefined ? <span>{suAPR.toFixed(2)}% APY</span> : <LoaderLine />}
                    </div>
                )}
            </div>
            <LinksDropdown onClose={closeDropdown} visible={isDropdownVisible} />
        </div>
    );
};
