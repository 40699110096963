import React from "react";
import { useHistory } from "react-router-dom";

import { ButtonGradient } from "ui-kit";
import { EmptyLoanIcon } from "ui-kit/images/icons";
import { BORROW_CURRENCY_NAME } from "utils/currency";

import "./styles.scss";

export const EmptyLoans = () => {
    const history = useHistory();

    const handleClick = () => {
        history.push("borrow");
    };

    return (
        <div className="empty-loans">
            <div className="empty-loans__icon">
                <EmptyLoanIcon />
            </div>
            <div className="empty-loans__title">No loans yet</div>
            <div className="empty-loans__description">
                Your loans details will be here after you take it. Then use the tools on this page to manage your debt.
            </div>

            <ButtonGradient className="empty-loans__button" onClick={handleClick}>
                Borrow {BORROW_CURRENCY_NAME}
            </ButtonGradient>
        </div>
    );
};
