import React, { useContext, useEffect, useMemo, useState } from "react";
import BigNumber from "bignumber.js";

import { StateContext } from "reducer/constants";
import { EarnDataItemType } from "utils/types";
import { RewardContractFactory, SuVaultFactory } from "utils/api";
import {
    BORROW_CURRENCY,
    getDecimals,
    getName,
    getSupportedTokensAddressesNoSU,
    SupportedTokensType,
} from "utils/currency";
import { toHRNumber } from "utils/bigNumber";
import { AssetsTable } from "./supportComponents/AssetsTable";
import { Disclaimer } from "./supportComponents/Disclaimer";

import "./styles.scss";

export const PageEarn = () => {
    const { currentAddress, chainId, possibleLockupPeriods } = useContext(StateContext);
    const [data, setData] = useState<Record<string, EarnDataItemType>>({});
    const [hasDisclaimer, setHasDisclaimer] = useState(false);
    const maxAPR = possibleLockupPeriods
        .map((v) => v.multiplicator1e18)
        .reduce((acc, v) => (acc.gt(v) ? acc : v), new BigNumber(0));

    const getTokenData = async (tokenAddress: string) => {
        try {
            const deposited = await SuVaultFactory.getDeposited(tokenAddress);
            const rewards = await RewardContractFactory.getPendingRewards(tokenAddress);
            let apr;
            try {
                apr = await RewardContractFactory.getPoolApr(tokenAddress);
            } catch (e) {
                apr = new BigNumber(0);
            }
            const decimals = getDecimals(getName(tokenAddress, chainId) as SupportedTokensType, chainId) ?? 0;
            const newItem = {
                deposited: deposited ? toHRNumber(deposited, decimals) : undefined,
                rewards: rewards ? toHRNumber(rewards, 18) : 0,
                apr: apr ? toHRNumber(apr.multipliedBy(100).multipliedBy(maxAPR), 18 + decimals) : 0,
                tokenName: getName(tokenAddress, chainId),
            } as EarnDataItemType;

            setData((oldData) => ({
                ...oldData,
                [tokenAddress]: newItem,
            }));
        } catch (e) {
            setData((oldData) => ({
                ...oldData,
                [tokenAddress]: {
                    deposited: undefined,
                    rewards: 0,
                    apr: 0,
                    tokenName: getName(tokenAddress, chainId) as SupportedTokensType,
                },
            }));
        }
    };

    useEffect(() => {
        if (currentAddress && chainId && maxAPR.gt(0)) {
            Promise.all(getSupportedTokensAddressesNoSU(chainId).map(getTokenData));
        }
    }, [currentAddress, chainId, maxAPR]);

    const tableData = useMemo(() => Object.values(data).sort((a, b) => b.apr - a.apr), [data]);
    const depositedTableData = useMemo(
        () =>
            Object.values(data)
                .filter((v) => v.deposited)
                .sort((a, b) => (b.deposited ?? 0) - (a.deposited ?? 0)),
        [data]
    );

    return (
        <div className="earn">
            {hasDisclaimer && (
                <Disclaimer onClose={() => setHasDisclaimer(false)} token1={BORROW_CURRENCY} token2="USDT" />
            )}
            {depositedTableData.length > 0 && (
                <div className="earn__section">
                    <div className="earn__section__title">Deposited</div>
                    <div className="earn__section__content">
                        <AssetsTable data={depositedTableData} needDeposits />
                    </div>
                </div>
            )}
            <div className="earn__section">
                <div className="earn__section__title">All assets</div>
                <div className="earn__section__content">
                    <AssetsTable data={tableData} needDeposits={false} />
                </div>
            </div>
        </div>
    );
};
