import React, { useContext, useEffect, useState } from "react";

import { useRewards, useLoader, useGlobalUpdate } from "hooks";
import { DataSliderContainer } from "components/DataSliderContainer";
import { InfoLine } from "components/InfoLine";
import { StateContext } from "reducer/constants";
import { BORROW_CURRENCY, getAddress, SupportedTokensType } from "utils/currency";
import { RewardContractFactory } from "utils/api";
import { addErrorNotification, addSuccessNotification } from "utils/notification";

import "./styles.scss";

type Props = {
    tokenName: SupportedTokensType;
};

export const Rewards = ({ tokenName }: Props) => {
    const { isLoading, start: startLoader, stop: stopLoader } = useLoader();
    const { chainId, currentAddress } = useContext(StateContext);
    const { rewards } = useRewards(tokenName);
    const { update } = useGlobalUpdate();
    const tokenAddress = getAddress(tokenName, chainId);
    const [harvestLockupTimestamp, setHarvestLockupTimestamp] = useState("-");

    useEffect(() => {
        if (chainId && tokenAddress && currentAddress) {
            RewardContractFactory.getHarvestLockup(tokenAddress).then((v) => setHarvestLockupTimestamp(v));
        }
    }, [chainId, tokenAddress, currentAddress]);

    const handleHarvest = async () => {
        try {
            startLoader();
            await RewardContractFactory.harvest(tokenAddress);
            addSuccessNotification("Harvest finished successfully");
            stopLoader();
            update();
        } catch (e) {
            addErrorNotification("Harvest error", e.message);
            stopLoader();
        }
    };

    return (
        <DataSliderContainer
            className="rewards"
            title="Rewards"
            name="SuDAO Deposit"
            value={rewards}
            token={BORROW_CURRENCY}
            buttonText={isLoading ? "Loading..." : "Harvest"}
            onClick={handleHarvest}
            isButtonDisabled={rewards === 0 || isLoading}
            contentPadding={1}
        >
            <div className="rewards__info">
                <InfoLine title="Harvest lockup end:" value={harvestLockupTimestamp} color="green" />
            </div>
        </DataSliderContainer>
    );
};
