const SMALL_FRACTION_DIGITS = 2;
const BIG_FRACTION_DIGITS = 8;

export const prettifyNumber = (n: number) => n.toLocaleString([], { maximumFractionDigits: SMALL_FRACTION_DIGITS });

export const prettifyNumberWithMinFraction = (n: number) =>
    n.toLocaleString([], {
        minimumFractionDigits: BIG_FRACTION_DIGITS,
        maximumFractionDigits: BIG_FRACTION_DIGITS,
    });

export const prettifyPrice = (n: number) => {
    if (n < 10) {
        return n.toLocaleString([], { maximumFractionDigits: 7 });
    }
    if (n < 100) {
        return n.toLocaleString([], { maximumFractionDigits: 4 });
    }

    return n.toLocaleString([], { maximumFractionDigits: 2 });
};
