import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Web3 from "web3";

import { PageLoan } from "pages/PageLoan";
import { PageBorrow } from "pages/PageBorrow";
import { PageAnalytics } from "pages/PageAnalytics";
import { PageLoanSettings } from "pages/PageLoanSettings";
import { PageEarnSettings } from "pages/PageEarnSettings";
import { PageEarn } from "pages/PageEarn";
import { PageInternalStats } from "pages/PageInternalStats";

interface Props {
    onConnect: () => void;
    web3: Web3;
}

export const Routes = ({ onConnect, web3 }: Props) => (
    <Switch>
        {/* @ts-ignore */}
        <Route exact path="/">
            <Redirect to="/borrow" />
        </Route>

        {/* @ts-ignore */}
        <Route exact path="/loan">
            <PageLoan />
        </Route>
        {/* @ts-ignore */}
        <Route exact path="/loan/:id">
            <PageLoanSettings />
        </Route>

        {/* @ts-ignore */}
        <Route exact path="/earn">
            <PageEarn />
        </Route>
        {/* @ts-ignore */}
        <Route exact path="/earn/:id">
            <PageEarnSettings />
        </Route>

        <Route path="/borrow">
            <PageBorrow onConnect={onConnect} />
        </Route>

        <Route path="/internal-stats">
            <PageInternalStats web3={web3} />
        </Route>

        <Route path="/analytics">
            <PageAnalytics />
        </Route>
    </Switch>
);
