import React, { useContext, useEffect, useMemo, useState } from "react";
import { PieChart, Pie, Cell } from "recharts";

import { ContentWithDot } from "ui-kit";
import { StateContext } from "reducer/constants";
import { COLORS } from "utils/chart";
import { getName, getSupportedTokensAddressesNoSU } from "utils/currency";
import { SuVaultFactory } from "utils/api";
import { toHRNumber } from "utils/bigNumber";
import { DEFAULT_NETWORK_ID } from "utils/network";

import { InfoContainer } from "../InfoContainer";

import "./styles.scss";

export const CollateralChart = () => {
    const [data, setData] = useState<Record<string, number>>({});
    const { isMounted, chainId } = useContext(StateContext);
    const currentChainId = chainId || DEFAULT_NETWORK_ID;

    useEffect(() => {
        Promise.all(
            getSupportedTokensAddressesNoSU(currentChainId).map(async (tokenAddress) => {
                const value = await SuVaultFactory.getTokenDebts(tokenAddress);
                if (value) {
                    setData((oldData) => ({
                        ...oldData,
                        [tokenAddress]: toHRNumber(value, 18),
                    }));
                }
            })
        );
    }, [isMounted, currentChainId]);

    const collaterals = useMemo(
        () =>
            Object.entries(data)
                .map(([address, value]) => ({
                    name: `${getName(address, currentChainId)} ($ ${Math.ceil(value).toLocaleString()})`,
                    value,
                }))
                .sort((a, b) => b.value - a.value),
        [data, currentChainId]
    );

    return (
        <InfoContainer
            title="Collateral structure"
            className="collateral-chart"
            classNameContent="collateral-chart__content"
        >
            <div className="collateral-chart__legend">
                {collaterals
                    ? collaterals.map(({ name }, index) => (
                          <ContentWithDot
                              key={name}
                              className="collateral-chart__legend__name"
                              color={COLORS[index % COLORS.length]}
                          >
                              {name}
                          </ContentWithDot>
                      ))
                    : "No collaterals"}
            </div>
            <PieChart width={150} height={150}>
                <Pie
                    data={collaterals}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={75}
                    innerRadius={40}
                    dataKey="value"
                >
                    {collaterals.map((entry, index) => (
                        <Cell key={`cell-${entry.name}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
            </PieChart>
        </InfoContainer>
    );
};
