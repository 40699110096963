import React, { useContext, useEffect, useState } from "react";

import { GradientHref } from "ui-kit";
import { StateContext } from "reducer/constants";
import { CommonFactory } from "utils/api";
import { toHRNumber } from "utils/bigNumber";
import { BORROW_CURRENCY } from "utils/currency";
import { DEFAULT_NETWORK_ID } from "utils/network";

import { InfoContainer } from "../InfoContainer";

import "./styles.scss";

export const OverviewChart = () => {
    const [supply, setSupply] = useState(0);
    const { chainId } = useContext(StateContext);

    useEffect(() => {
        CommonFactory.totalSupply(BORROW_CURRENCY, chainId || DEFAULT_NETWORK_ID).then((newTotalSupply) =>
            setSupply(toHRNumber(newTotalSupply, 18))
        );
    }, [chainId]);

    const data = [
        {
            name: `${BORROW_CURRENCY} supply`,
            value: supply,
        },
    ];

    return (
        <InfoContainer title="Overview" className="overview-chart" classNameContent="overview-chart__content">
            <div className="overview-chart__legend">
                {data.map(({ name, value }, index) => (
                    <div key={name} className="overview-chart__legend__data">
                        <GradientHref>{name}</GradientHref>
                        <div>${value.toLocaleString()}</div>
                    </div>
                ))}
            </div>
        </InfoContainer>
    );
};
