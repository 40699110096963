import React, { useContext } from "react";
import { useParams } from "react-router-dom";

import { Actions } from "reducer";
import { useDeposited, usePrice, useLiquidationPrice, useLockup, useAPR, useRewards } from "hooks";
import { Button, LoaderLine } from "ui-kit";
import { InfoLine } from "components/InfoLine";
import { DataSliderContainer } from "components/DataSliderContainer";
import { DispatchContext } from "reducer/constants";
import { BORROW_CURRENCY_NAME, SupportedTokensType } from "utils/currency";
import { toHRNumber } from "utils/bigNumber";
import { prettifyPrice } from "utils/prettify";

import "./styles.scss";

export const CollateralInfo = () => {
    const { id } = useParams();
    const tokenName = id as SupportedTokensType;
    const dispatch = useContext(DispatchContext);
    const { deposited } = useDeposited(tokenName);
    const { tokenPrice } = usePrice(tokenName);
    const { liquidationPrice } = useLiquidationPrice(tokenName);
    const { lockupMonths, multiplicator1e18 } = useLockup(tokenName);
    const { rewardBN } = useAPR(tokenName);
    const { rewards } = useRewards(tokenName);

    const handleWithdraw = () => {
        dispatch({ type: Actions.SetActionModalTab, payload: "withdraw" });
        dispatch({ type: Actions.SetIsActionModalOpened, payload: true });
    };

    const handleDeposit = () => {
        dispatch({ type: Actions.SetActionModalTab, payload: "deposit" });
        dispatch({ type: Actions.SetIsActionModalOpened, payload: true });
    };

    const depositRewards =
        rewardBN && multiplicator1e18
            ? toHRNumber(rewardBN.multipliedBy(100).multipliedBy(multiplicator1e18), 18 + 18)
            : 0;

    return (
        <DataSliderContainer
            className="collateral-info"
            title="Collateral Info"
            name={`${id} Deposit`}
            value={deposited.toString()}
            token={id as SupportedTokensType}
            buttonText="Deposit"
            contentPadding={1}
            onClick={handleDeposit}
        >
            <div className="collateral-info__content">
                <div className="collateral-info__info">
                    <InfoLine
                        title="Accumulated Rewards:"
                        value={`${rewards.toFixed(2)} ${BORROW_CURRENCY_NAME}`}
                        color="green"
                    />
                    <InfoLine title="Deposit Rewards:" value={`${depositRewards.toFixed(2)}% APY`} color="green" />
                    <InfoLine title="Time Boost:" value={lockupMonths === 0 ? "No boost" : `${lockupMonths} Months`} />
                </div>
                <div className="collateral-info__prices">
                    <div className="collateral-info__prices__price">
                        <div className="collateral-info__prices__price__title">Current price:</div>
                        <div className="collateral-info__prices__price__value">${prettifyPrice(tokenPrice)}</div>
                    </div>
                    <div className="collateral-info__prices__price">
                        <div className="collateral-info__prices__price__title">Liquidation price:</div>
                        {liquidationPrice !== undefined ? (
                            <div className="collateral-info__prices__price__value">
                                {liquidationPrice === "inf" ? "-" : `$${prettifyPrice(liquidationPrice)}`}
                            </div>
                        ) : (
                            <LoaderLine />
                        )}
                    </div>
                    <Button width={88} padding="7px 0" onClick={handleWithdraw}>
                        Withdraw
                    </Button>
                </div>
            </div>
        </DataSliderContainer>
    );
};
