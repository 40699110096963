import { MONTH } from "./number";

export const boosterLockupOptions = [
    {
        id: 1,
        name: "No boost",
    },
    {
        id: 3 * MONTH,
        name: "3 Mo",
    },
    {
        id: 6 * MONTH,
        name: "6 Mo",
    },
    {
        id: 12 * MONTH,
        name: "12 Mo",
    },
];

export type BoosterLockupType = {
    id: number;
    name: string;
};
