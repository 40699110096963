import Mock1INCH from "submodule-contract-artifacts/goerli/Mock1INCH.json";
import MockOracle from "submodule-contract-artifacts/goerli/MockOracle.json";
import MockSHIB from "submodule-contract-artifacts/goerli/MockSHIB.json";
import MockUSDT from "submodule-contract-artifacts/goerli/MockUSDT.json";
import MockWBTC from "submodule-contract-artifacts/goerli/MockWBTC.json";
import MockWETH from "submodule-contract-artifacts/goerli/MockWETH.json";
import RewardChefV2 from "submodule-contract-artifacts/goerli/RewardChefV2.json";
import SuAccessControlSingleton from "submodule-contract-artifacts/goerli/SuAccessControlSingleton.json";
import SuCdpRegistry from "submodule-contract-artifacts/goerli/SuCdpRegistry.json";
import SuChainlinkOracle from "submodule-contract-artifacts/goerli/SuChainlinkOracle.json";
import SuCollateralRegistry from "submodule-contract-artifacts/goerli/SuCollateralRegistry.json";
import SuLiquidation from "submodule-contract-artifacts/goerli/SuLiquidation.json";
import SuManager from "submodule-contract-artifacts/goerli/SuManager.json";
import SuOracleAggregator from "submodule-contract-artifacts/goerli/SuOracleAggregator.json";
import StablePro from "submodule-contract-artifacts/goerli/StablePro.json";
import SuVault from "submodule-contract-artifacts/goerli/SuVault.json";

export type ContractType = { address: string; abi: any[] };

export const allContracts = {
    Mock1INCH,
    MockOracle,
    MockSHIB,
    MockUSDT,
    MockWBTC,
    MockWETH,
    RewardChefV2,
    SuAccessControlSingleton,
    SuCdpRegistry,
    SuChainlinkOracle,
    SuCollateralRegistry,
    SuLiquidation,
    SuManager,
    SuOracleAggregator,
    StablePro,
    SuVault,
} as Record<string, ContractType>;
