import React from "react";
import cn from "classnames";

import { getTokenIcon, SupportedTokensType } from "utils/currency";

import "./styles.scss";

interface MergedIconsProps {
    className?: string;
    token1: SupportedTokensType;
    token2: SupportedTokensType;
    size?: "m" | "l";
}

export const MergedIcons = ({ token1, token2, className, size = "m" }: MergedIconsProps) => {
    return (
        <div className={cn("merged-icons", `merged-icons--${size}`, className)}>
            {getTokenIcon(token1)}
            {getTokenIcon(token2)}
        </div>
    );
};
