import React, { useContext } from "react";
import cn from "classnames";

import { Tooltip, LoaderLine } from "ui-kit";
import BigNumber from "bignumber.js";

import { useLiquidationRatio, useAPR, useBorrowAPR, useSuAPR, useLiquidationPriceByAmount } from "hooks";
import { StateContext } from "reducer/constants";
import { ArrowDownIcon } from "ui-kit/images/icons";
import { BORROW_CURRENCY, SupportedTokensType } from "utils/currency";
import { toHRNumber } from "utils/bigNumber";
import { BoosterLockupType } from "utils/lockup";
import { BoostModal } from "../BoostModal";

import "./styles.scss";

interface BorrowInfoProps {
    visible: boolean;
    onToggle: () => void;
    tokenName: SupportedTokensType;
    boosterLockupValue: BoosterLockupType;
    additionalCollateralAmount: BigNumber;
    additionalStablecoinAmount: BigNumber;
}

export const BorrowInfo = ({
    visible,
    onToggle,
    tokenName,
    boosterLockupValue,
    additionalCollateralAmount,
    additionalStablecoinAmount,
}: BorrowInfoProps) => {
    const { possibleLockupPeriods } = useContext(StateContext);
    const { liquidationPrice } = useLiquidationPriceByAmount(
        tokenName,
        additionalCollateralAmount,
        additionalStablecoinAmount
    );
    const { liquidationRatio } = useLiquidationRatio(tokenName);
    const { rewardBN } = useAPR(tokenName);
    const { borrowApr } = useBorrowAPR(tokenName);
    const { suAPR } = useSuAPR();

    const multiplicator =
        possibleLockupPeriods.find((v) => v.lockupPeriodSeconds === boosterLockupValue.id)?.multiplicator1e18 ?? 0;

    const depositRewardsAPR =
        rewardBN !== undefined && multiplicator !== undefined
            ? toHRNumber(rewardBN.multipliedBy(multiplicator).multipliedBy(100), 18 + 18)
            : undefined;

    const openLTV = () => {
        window.open("https://www.investopedia.com/terms/l/loantovalue.asp", "_blank");
    };

    return (
        <div className={cn("borrow-info", { "borrow-info--visible": visible })}>
            <div className="borrow-info__visible-data" onClick={onToggle}>
                <div className="borrow-info__visible-data__title">Estimated rewards</div>
                <div className="borrow-info__visible-data__data">
                    <ArrowDownIcon className="borrow-info__visible-data__data__icon" />
                </div>
            </div>
            <div className="borrow-info__hidden-data">
                <div className="borrow-info__hidden-data__line">
                    <div className="borrow-info__hidden-data__line__title">
                        Deposit rewards:&nbsp;
                        <Tooltip id="depositRewards">
                            <span>SuDAO rewards you receive as long as you have the collateral in deposit</span>
                        </Tooltip>
                    </div>
                    {depositRewardsAPR !== undefined ? (
                        <div className="borrow-info__hidden-data__line__content">
                            <div className="borrow-info__hidden-data__line__data green-color">
                                +{depositRewardsAPR.toFixed(1)}% APR
                            </div>
                        </div>
                    ) : (
                        <LoaderLine />
                    )}
                </div>
                <div className="borrow-info__hidden-data__line">
                    <div className="borrow-info__hidden-data__line__title">
                        SU rewards:&nbsp;
                        <Tooltip id="suRewards">
                            <span>
                                {BORROW_CURRENCY} rewards you receive as long as you have {BORROW_CURRENCY} on your
                                balance
                            </span>
                        </Tooltip>
                    </div>
                    {suAPR !== undefined ? (
                        <div className="borrow-info__hidden-data__line__data green-color">+{suAPR.toFixed(1)}% APR</div>
                    ) : (
                        <LoaderLine />
                    )}
                </div>
                <div className="borrow-info__hidden-data__line">
                    <div className="borrow-info__hidden-data__line__title">
                        Borrow fee:&nbsp;
                        <Tooltip id="borrowFee">
                            <span>Loan retention fee after borrow</span>
                        </Tooltip>
                    </div>
                    {borrowApr !== undefined ? (
                        <div
                            className={cn(
                                "borrow-info__hidden-data__line__data",
                                borrowApr === 0 ? "green-color" : "red-color"
                            )}
                        >
                            -{borrowApr.toFixed(1)}% APR
                        </div>
                    ) : (
                        <LoaderLine width={60} />
                    )}
                </div>

                <div className="borrow-info__hidden-data__line borrow-info__hidden-data__line--padded">
                    <div className="borrow-info__hidden-data__line__title">
                        {tokenName} liq price:&nbsp;
                        <Tooltip id="borrowFee">
                            <span>
                                If {tokenName} has such a price or lower, then liquidation of the loan will begin{" "}
                            </span>
                        </Tooltip>
                    </div>
                    {liquidationPrice !== undefined ? (
                        <div className="borrow-info__hidden-data__line__data">
                            {liquidationPrice === "inf" || liquidationPrice === 0
                                ? "-"
                                : `$${liquidationPrice.toFixed(1).toLocaleString()}`}
                        </div>
                    ) : (
                        <LoaderLine width={80} />
                    )}
                </div>
                <div className="borrow-info__hidden-data__line borrow-info__hidden-data__line--padded">
                    <div className="borrow-info__hidden-data__line__title">
                        Liquidation LTV: &nbsp;
                        <Tooltip id="borrowFee" onClick={openLTV}>
                            <span>
                                The loan-to-value (LTV) ratio is relation between amoun tof money you borrowed divided
                                to a value of your collateral.
                            </span>
                        </Tooltip>
                    </div>
                    {liquidationRatio !== undefined ? (
                        <div className="borrow-info__hidden-data__line__data">{liquidationRatio}%</div>
                    ) : (
                        <LoaderLine width={50} />
                    )}
                </div>
            </div>
        </div>
    );
};
