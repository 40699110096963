import React from "react";
import cn from "classnames";

import { Tooltip } from "ui-kit";
import { BoosterLockupType } from "utils/types";

import "./styles.scss";

interface DepositBoosterProps {
    values: BoosterLockupType[];
    value: BoosterLockupType;
    onChange: (id: number) => void;
    className?: string;
}

export const DepositBooster = ({ values, value, onChange, className }: DepositBoosterProps) => (
    <div className={cn("deposit-booster", className)}>
        <div className="deposit-booster__title">
            <span>Time Boost</span>
            <Tooltip id="lockup">
                <span>
                    For how long are you planing to keep you deposit. The longer you keep - more rewards you get. You
                    are able to withdraw your tokens at any time, but if you do it before lockup is over, 80% of rewards
                    goes back to DAO treasury.
                </span>
            </Tooltip>
        </div>
        <div className="deposit-booster__values">
            {values.map((v) => (
                <div
                    key={v.id}
                    className={cn("deposit-booster__value", {
                        "deposit-booster__value--selected": v.id === value.id,
                    })}
                    onClick={() => onChange(v.id)}
                >
                    {v.name}
                </div>
            ))}
        </div>
    </div>
);
