import React from "react";

import tokenList from "submodule-contract-artifacts/stableunit.tokenlist.json";
import {
    UsdProIcon,
    OneInchIcon,
    WBTCIcon,
    StETHIcon,
    SHIBIcon,
    EthIcon,
    SFrxETHIcon,
    WStETHIcon,
    REthIcon,
    SwEthIcon,
} from "ui-kit/images/currency";
import { TokenMetadata } from "./types";

export type SupportedTokensType =
    | "WETH"
    | "stETH"
    | "rETH"
    | "swETH"
    | "sfrxETH"
    | "wstETH"
    | "wstETH-ETH LP"
    | "WBTC"
    | "1INCH"
    | "SHIB"
    | "USDPro"
    | "EURPro";

export const BORROW_CURRENCY = "EURPro" as SupportedTokensType;
export const BORROW_CURRENCY_NAME = "Euro Pro" as SupportedTokensType;

export const isSupportedTokenInList = (symbol: string) =>
    ![BORROW_CURRENCY, "DAI", "tUSDT", "veSuDAO", "USDPro", "EURPro", "SuDAO"].includes(symbol);

export const getSupportedTokens = (chainId: number) => tokenList.tokens.filter((v) => v.chainId === chainId);
export const getSupportedTokensAddresses = (chainId: number) => getSupportedTokens(chainId).map((v) => v.address);
export const getSupportedTokensAddressesNoSU = (chainId: number) =>
    getSupportedTokens(chainId)
        .filter((v) => isSupportedTokenInList(v.symbol))
        .map((v) => v.address);

export const getTokenByName = (name: SupportedTokensType, chainId: number | undefined) =>
    tokenList.tokens.find((v) => v.symbol === name && v.chainId === chainId);

export const isTokenInGroup = (name: SupportedTokensType, chainId: number | undefined) =>
    !!getTokenByName(name, chainId)?.group;

export const getTokenGroup = (name: SupportedTokensType, chainId: number | undefined) => {
    const group = getTokenByName(name, chainId)?.group;

    if (!group) {
        return [];
    }

    return tokenList.tokens.filter((v) => v.chainId === chainId && v.group === group);
};

export const getAddress = (name: SupportedTokensType, chainId: number | undefined) =>
    getTokenByName(name, chainId)?.address;

export const getName = (address: string, chainId: number | undefined) =>
    tokenList.tokens.find((v) => v.address === address && v.chainId === chainId)?.symbol as
        | SupportedTokensType
        | undefined;

export const getDecimals = (name: SupportedTokensType, chainId: number | undefined) =>
    getTokenByName(name, chainId)?.decimals;

export const getTokenIcon = (tokenName: SupportedTokensType) => {
    switch (tokenName) {
        case "wstETH-ETH LP":
        case "wstETH":
            return <WStETHIcon />;
        case "sfrxETH":
            return <SFrxETHIcon />;
        case "stETH":
            return <StETHIcon />;
        case "WETH":
            return <EthIcon />;
        case "swETH":
            return <SwEthIcon />;
        case "rETH":
            return <REthIcon />;
        case "WBTC":
            return <WBTCIcon />;
        // case "USDT":
        //     return <UsdtIcon />;
        case "1INCH":
            return <OneInchIcon />;
        case "SHIB":
            return <SHIBIcon />;
        case "USDPro":
            return <UsdProIcon />;
        case "EURPro":
            return <UsdProIcon />;
        default:
            return null;
    }
};

export const addToMetamask = (tokenMetadata: TokenMetadata) =>
    window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
            type: "ERC20",
            options: tokenMetadata,
        },
    });
