import React from "react";
import { useHistory, useParams } from "react-router-dom";

import { BackButton } from "ui-kit";
import { ActionsModal } from "components/ActionsModal";
import { SupportedTokensType } from "utils/currency";
import { CollateralInfo } from "./supportComponents/CollateralInfo";
import { LoanInfo } from "./supportComponents/LoanInfo";

import "./styles.scss";

export const PageLoanSettings = () => {
    const history = useHistory();
    const { id } = useParams();

    const handleBack = () => {
        history.push(`/loan`);
    };

    return (
        <div className="loan-settings">
            <BackButton className="loan-settings__back" onClick={handleBack} text="Back to list" />
            <CollateralInfo />
            <LoanInfo />
            <ActionsModal token={id as SupportedTokensType | undefined} />
        </div>
    );
};
