import React, { useContext } from "react";
import Modal from "react-modal";
import cn from "classnames";

import { Actions } from "reducer";
import { DispatchContext, StateContext } from "reducer/constants";
import { CloseIcon, DepositIcon, RepayIcon, WithdrawIcon } from "ui-kit/images/icons";
import { TabDataType, TabType } from "utils/types";
import { getTokenIcon, SupportedTokensType } from "utils/currency";

import { DepositContent } from "./supportComponents/DepositContent";
import { WithdrawContent } from "./supportComponents/WithdrawContent";
import { RepayContent } from "./supportComponents/RepayContent";

import "./styles.scss";

interface ActionsModalProps {
    onClose?: () => void;
    token?: SupportedTokensType;
}

const TABS: TabDataType[] = [
    {
        id: "deposit",
        name: "Deposit",
        icon: DepositIcon,
    },
    {
        id: "repay",
        name: "Repay",
        icon: RepayIcon,
    },
    {
        id: "withdraw",
        name: "Withdraw",
        icon: WithdrawIcon,
    },
];

export const ActionsModal = ({ onClose, token }: ActionsModalProps) => {
    const { actionModalTab, isActionModalOpened } = useContext(StateContext);
    const dispatch = useContext(DispatchContext);

    if (!token) {
        return null;
    }

    const handleSelectTab = (tab: TabType) => () => {
        dispatch({ type: Actions.SetActionModalTab, payload: tab });
    };

    const handleClose = () => {
        dispatch({ type: Actions.SetIsActionModalOpened, payload: false });
        if (onClose) {
            onClose();
        }
    };

    return (
        <Modal
            isOpen={isActionModalOpened}
            onRequestClose={handleClose}
            className="actions-modal"
            overlayClassName="actions-modal-overlay"
        >
            <CloseIcon className="actions-modal__close" onClick={handleClose} />
            <div className="actions-modal__title">
                {getTokenIcon(token)}
                <div className="actions-modal__title__text">{token}</div>
            </div>
            <div className="actions-modal__tabs">
                {TABS.map((tab) => (
                    <div
                        key={tab.name}
                        onClick={tab.id !== actionModalTab ? handleSelectTab(tab.id) : undefined}
                        className={cn("actions-modal__tab", {
                            "actions-modal__tab--selected": tab.id === actionModalTab,
                        })}
                    >
                        <tab.icon className="actions-modal__tab__image" />
                        <div className="actions-modal__tab__name">{tab.name}</div>
                    </div>
                ))}
            </div>
            {actionModalTab === "deposit" && <DepositContent token={token} onClose={handleClose} />}
            {actionModalTab === "repay" && <RepayContent token={token} onClose={handleClose} />}
            {actionModalTab === "withdraw" && <WithdrawContent token={token} onClose={handleClose} />}
        </Modal>
    );
};
