import { useContext, useEffect, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import BigNumber from "bignumber.js";

import { StateContext } from "reducer/constants";
import { SuManagerFactory } from "utils/api";
import { SupportedTokensType } from "utils/currency";
import { toHRNumber } from "utils/bigNumber";

type ReturnType = {
    liquidationPriceBN: BigNumber | undefined;
    liquidationPrice: number | "inf" | undefined;
};

export const useLiquidationPriceByAmount = (
    currency: SupportedTokensType,
    additionalCollateralAmount: BigNumber,
    additionalStablecoinAmount: BigNumber
) => {
    const [liquidationPriceBN, setLiquidationPriceBN] = useState<BigNumber>();
    const { chainId } = useContext(StateContext);

    const additionalCollateralAmountStr = additionalCollateralAmount.toString();
    const additionalStablecoinAmountStr = additionalStablecoinAmount.toString();

    const getLiquidationPriceByAmount = useMemo(
        () =>
            debounce(
                (
                    currency_: SupportedTokensType,
                    chainId_: number | undefined,
                    additionalCollateralAmount_: BigNumber,
                    additionalStablecoinAmount_: BigNumber
                ) => {
                    setLiquidationPriceBN(undefined);
                    SuManagerFactory.getLiquidationPriceByAmount(
                        currency_,
                        chainId_,
                        additionalCollateralAmount_,
                        additionalStablecoinAmount_
                    ).then((v) => setLiquidationPriceBN(v));
                },
                500
            ),
        []
    );

    useEffect(() => {
        if (currency && chainId && !additionalStablecoinAmount.eq(0) && !additionalStablecoinAmount.eq(0)) {
            getLiquidationPriceByAmount(currency, chainId, additionalCollateralAmount, additionalStablecoinAmount);
        } else {
            setLiquidationPriceBN(new BigNumber(0));
        }
    }, [currency, chainId, additionalCollateralAmountStr, additionalStablecoinAmountStr]);

    const liquidationPrice = liquidationPriceBN ? toHRNumber(liquidationPriceBN, 18, 6) : undefined;
    const isInfinite = liquidationPriceBN && liquidationPriceBN?.toString(10).length >= 78;

    return {
        liquidationPriceBN,
        liquidationPrice: isInfinite ? "inf" : liquidationPrice,
    } as ReturnType;
};
