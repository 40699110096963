import { useContext } from "react";
import BigNumber from "bignumber.js";

import { StateContext } from "reducer/constants";
import { RewardContractFactory } from "utils/api";
import { getAddress, SupportedTokensType } from "utils/currency";
import { toHRNumber } from "utils/bigNumber";

import { useParameter } from "./useParameter";

export const useRewards = (currency: SupportedTokensType) => {
    const { chainId } = useContext(StateContext);
    const tokenAddress = getAddress(currency, chainId);
    const rewardsBN = useParameter<BigNumber>(
        `rewards ${currency}`,
        () => RewardContractFactory.getPendingRewards(tokenAddress),
        tokenAddress
    );

    return {
        rewardsBN,
        rewards: rewardsBN ? toHRNumber(rewardsBN, 18) : 0,
    };
};
