import React from "react";
import cn from "classnames";

import { Button } from "ui-kit";
import { getTokenIcon, SupportedTokensType } from "utils/currency";

import "./styles.scss";

interface DataSliderContainerProps {
    id?: string;
    title: string;
    name: string;
    value: string | number;
    token: SupportedTokensType;
    buttonText: string;
    onClick?: () => void;
    children?: React.ReactNode;
    className?: string;
    contentPadding?: number;
    isButtonDisabled?: boolean;
}

export const DataSliderContainer = ({
    id,
    title,
    value,
    name,
    children,
    buttonText,
    token,
    onClick,
    className,
    contentPadding,
    isButtonDisabled,
}: DataSliderContainerProps) => (
    <div className={cn("data-slider-container", className)} id={id}>
        <div className="data-slider-container__title">{title}</div>
        <div className="data-slider-container__content" style={{ padding: contentPadding }}>
            <div className="data-slider-container__top">
                <div className="data-slider-container__top__info">
                    <div className="data-slider-container__top__info__icon">{getTokenIcon(token)}</div>
                    <div>
                        <div className="data-slider-container__top__info__title">{name}</div>
                        <div className="data-slider-container__top__info__value">{value}</div>
                    </div>
                </div>
                <Button id={`${id}-button`} width={88} padding="7px 0" onClick={onClick} disabled={isButtonDisabled}>
                    {buttonText}
                </Button>
            </div>
            {children}
        </div>
    </div>
);
