import React from "react";
import { useHistory, useParams } from "react-router-dom";

import { BackButton } from "ui-kit";
import { ActionsModal } from "components/ActionsModal";
import { SupportedTokensType } from "utils/currency";

import { DepositInfo } from "./supportComponents/DepositInfo";
import { EarnMore } from "./supportComponents/EarnMore";
import { Rewards } from "./supportComponents/Rewards";

import "./styles.scss";

export const PageEarnSettings = () => {
    const history = useHistory();
    const { id } = useParams();
    const tokenName = id as SupportedTokensType;

    const handleBack = () => {
        history.push(`/earn`);
    };

    return (
        <div className="earn-settings">
            <BackButton className="earn-settings__back" onClick={handleBack} text="Back to list" />
            <DepositInfo />
            <div className="earn-settings__side-container">
                <EarnMore />
                <Rewards tokenName={tokenName} />
            </div>
            <ActionsModal token={id as SupportedTokensType | undefined} />
        </div>
    );
};
