import React from "react";

import { ButtonGray } from "ui-kit";
import { EtherscanIcon } from "ui-kit/images/icons";
import { getEtherscanAddress } from "utils/network";
import { getTokenIcon, SupportedTokensType } from "utils/currency";

import "./styles.scss";

interface BoostModalItemProps {
    onStake: (name: SupportedTokensType) => void;
    address: string;
    name: SupportedTokensType;
    apr: number;
    disabled?: boolean;
}

export const BoostModalItem = ({ name, apr, address, onStake, disabled = false }: BoostModalItemProps) => {
    const handleStake = () => {
        onStake(name);
    };

    const handleOpenAddress = () => {
        window.open(getEtherscanAddress(address), "_blank");
    };

    return (
        <div className="boost-modal-item">
            <div className="boost-modal-item__name">
                {getTokenIcon(name)}
                <div className="boost-modal-item__name__text">{name}</div>
                <EtherscanIcon className="boost-modal-item__name__etherscan" onClick={handleOpenAddress} />
            </div>
            <div className="boost-modal-item__apr">{apr.toFixed(1)}% APR*</div>
            <ButtonGray disabled={disabled} className="boost-modal-item__button" onClick={handleStake}>
                Stake
            </ButtonGray>
        </div>
    );
};
