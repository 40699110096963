import React from "react";

import { useDevice } from "hooks";
import { GradientBorder } from "ui-kit";
import { PageBorrowContent } from "./supportComponents/PageBorrowContent";

import "./styles.scss";

interface Props {
    onConnect: () => void;
}

export const PageBorrow = ({ onConnect }: Props) => {
    const { isMobile } = useDevice();

    return isMobile ? (
        <PageBorrowContent onConnect={onConnect} />
    ) : (
        <GradientBorder borderRadius={24} className="borrow-container">
            <PageBorrowContent onConnect={onConnect} />
        </GradientBorder>
    );
};
