import React, { ChangeEvent, useRef } from "react";
import cn from "classnames";

import "./styles.scss";

interface TokenSliderProps {
    min: number;
    max: number;
    value: number;
    disabled?: boolean;
    onChange?: (n: number) => void;
    className?: string;
    maxAvailableValue?: number;
}

export const TokenSlider = ({
    max,
    min,
    value,
    onChange,
    className,
    disabled,
    maxAvailableValue,
}: TokenSliderProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const ltv = max - min > 0 ? Math.floor((value / (max - min)) * 100) : 0;
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = +e.target.value;
        if (maxAvailableValue && newValue > maxAvailableValue && onChange) {
            onChange(maxAvailableValue);
            return;
        }
        if (onChange) {
            onChange(newValue);
        }

        if (inputRef.current) {
            const percentage = (newValue / (max - min)) * 100;

            inputRef.current?.classList.remove("first", "second", "third", "fourth", "fifth");

            if (percentage < 20) {
                inputRef.current?.classList.add("first");
            } else if (percentage < 40) {
                inputRef.current?.classList.add("second");
            } else if (percentage < 60) {
                inputRef.current?.classList.add("third");
            } else if (percentage < 80) {
                inputRef.current?.classList.add("fourth");
            } else {
                inputRef.current?.classList.add("fifth");
            }
        }
    };

    return (
        <div className={cn("token-slider", className)}>
            <input
                className="token-slider__slider"
                ref={inputRef}
                type="range"
                min={min}
                max={max}
                value={value}
                disabled={disabled}
                onChange={handleChange}
                step="0.1"
            />
            <div className="token-slider__ltv">LTV {ltv > 100 ? ">100" : ltv}%</div>
        </div>
    );
};
