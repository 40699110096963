import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Progress } from "rsuite";

import { Actions } from "reducer";
import { useDevice } from "hooks";
import { Button, GradientBorder } from "ui-kit";
import { ActionsModal } from "components/ActionsModal";
import { DispatchContext } from "reducer/constants";
import { SortIcon } from "ui-kit/images/icons";
import { DirectionType, LoanDataType } from "utils/types";
import { BORROW_CURRENCY, getTokenIcon, SupportedTokensType } from "utils/currency";

import "./styles.scss";

interface LoansTableType {
    data: LoanDataType[];
}

export const LoansTable = ({ data }: LoansTableType) => {
    const { isMobile } = useDevice();
    const history = useHistory();
    const dispatch = useContext(DispatchContext);

    const [selectedToken, setSelectedToken] = useState<SupportedTokensType>();
    const [currentData, setCurrentData] = useState(data);
    const [direction, setDirection] = useState({
        tokenName: undefined,
        debt: undefined,
        collateralValue: undefined,
        ltv: undefined,
    } as Record<keyof LoanDataType, DirectionType>);

    useEffect(() => {
        if (data) {
            setCurrentData(data);
        }
    }, [data]);

    const sortBy = (field: keyof LoanDataType) => () => {
        const filedDirection = direction[field];
        const result = [...currentData].sort((a, b) => {
            const multiplier = filedDirection === "asc" ? 1 : -1;
            return a[field] > b[field] ? multiplier : -multiplier;
        });
        setDirection({ ...direction, [field]: filedDirection === "asc" ? "desc" : "asc" });
        setCurrentData(result);
    };

    const openActionsModal = (token: SupportedTokensType) => () => {
        dispatch({ type: Actions.SetIsActionModalOpened, payload: true });
        setSelectedToken(token);
    };
    const closeActionsModal = () => {
        setSelectedToken(undefined);
    };

    const handleOpenToken = (tokenName: string) => () => {
        history.push(`/loan/${tokenName}`);
    };

    return (
        <>
            <table className="loans-table">
                <thead className="loans-table__header">
                    <tr>
                        <th>
                            <div className="loans-table__header__cell" onClick={sortBy("tokenName")}>
                                Loans
                                <SortIcon />
                            </div>
                        </th>
                        <th>
                            <div className="loans-table__header__cell" onClick={sortBy("debt")}>
                                {BORROW_CURRENCY} Debt
                                <SortIcon />
                            </div>
                        </th>
                        {!isMobile && (
                            <th>
                                <div className="loans-table__header__cell" onClick={sortBy("collateralValue")}>
                                    Collateral value
                                    <SortIcon />
                                </div>
                            </th>
                        )}
                        <th>
                            <div className="loans-table__header__cell" onClick={sortBy("ltv")}>
                                LTV
                                <SortIcon />
                            </div>
                        </th>
                        <th />
                    </tr>
                </thead>
                <tbody className="loans-table__body">
                    {currentData.map((item) => (
                        <tr className="loans-table__body__line" key={item.tokenName}>
                            <td>
                                <div className="loans-table__body__line__icon">
                                    {getTokenIcon(item.tokenName)}
                                    <div>{item.tokenName}</div>
                                </div>
                            </td>
                            <td>{item.debt.toLocaleString()}</td>
                            {!isMobile && <td>${item.collateralValue.toLocaleString()}</td>}
                            <td width={isMobile ? 56 : 160}>
                                <div className="loans-table__body__line__ltv">
                                    <Progress.Line
                                        className="loans-table__body__line__ltv__progress"
                                        strokeWidth={4}
                                        percent={item.ltv}
                                        showInfo={false}
                                    />
                                    {item.ltv.toLocaleString()}%
                                </div>
                            </td>
                            <td>
                                {isMobile ? (
                                    <GradientBorder borderRadius={10} className="loans-table__body__line__button">
                                        <div
                                            onClick={openActionsModal(item.tokenName)}
                                            className="loans-table__body__line__button-content"
                                        >
                                            ..
                                        </div>
                                    </GradientBorder>
                                ) : (
                                    <div className="loans-table__body__line__button">
                                        <Button width={80} padding="5px 17px" onClick={handleOpenToken(item.tokenName)}>
                                            Manage
                                        </Button>
                                    </div>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {isMobile && <ActionsModal onClose={closeActionsModal} token={selectedToken} />}
        </>
    );
};
