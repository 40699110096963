import React, { useContext, useMemo, useState } from "react";

import { useAvailableToWithdraw, useGlobalUpdate, useLoader } from "hooks";
import { ButtonGradient, GradientHref } from "ui-kit";
import { StateContext } from "reducer/constants";
import { getDecimals, SupportedTokensType } from "utils/currency";
import { SuManagerFactory } from "utils/api";
import { addErrorNotification, addSuccessNotification } from "utils/notification";
import { fromHRNumber } from "utils/bigNumber";

import { TokenInput } from "../../../TokenInput";
import { MaxButton } from "../../../MaxButton";

import "./styles.scss";

interface DepositContentProps {
    token: SupportedTokensType;
    onClose: () => void;
}

export const WithdrawContent = ({ token, onClose }: DepositContentProps) => {
    const { chainId } = useContext(StateContext);
    const [value, setValue] = useState<number>();
    const decimals = getDecimals(token, chainId);
    const { availableToWithdraw } = useAvailableToWithdraw(token);
    const { isLoading, start: startLoader, stop: stopLoader } = useLoader();
    const { update } = useGlobalUpdate();

    const handleValueChange = (newValue?: number) => {
        setValue(newValue);
    };

    const handleAction = async () => {
        if (value) {
            try {
                startLoader();
                await SuManagerFactory.withdraw(token, fromHRNumber(value, decimals ?? 18), chainId);
                addSuccessNotification("Withdraw finished successfully");
                stopLoader();
                update();
                setValue(0);
                onClose();
            } catch (e) {
                addErrorNotification("Withdraw error", e.message);
                stopLoader();
            }
        }
    };

    const handleMaxClick = () => {
        if (availableToWithdraw) {
            setValue(availableToWithdraw);
        }
    };

    const hasEnoughBalance = !value || value <= availableToWithdraw;
    const btnText = useMemo(() => {
        if (isLoading) {
            return "Loading...";
        }
        if (!hasEnoughBalance) {
            return "Insufficient balance";
        }
        return "WITHDRAW";
    }, [isLoading, hasEnoughBalance]);

    return (
        <div className="withdraw-content">
            <TokenInput
                id="withdraw-content"
                headerContent={
                    <div className="loan-info__content__input__available">
                        <GradientHref>Available to withdraw: {availableToWithdraw}</GradientHref>
                        <MaxButton onClick={handleMaxClick} />
                    </div>
                }
                showBalance={false}
                className="withdraw-content__token-input"
                tokenName={token}
                value={value}
                onValueChange={handleValueChange}
                isTokenFixed
            />
            <ButtonGradient
                className="withdraw-content__button"
                onClick={handleAction}
                disabled={!value || !hasEnoughBalance || isLoading}
                loading={isLoading}
            >
                {btnText}
            </ButtonGradient>
        </div>
    );
};
