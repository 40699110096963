import React, { useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { Actions } from "reducer";
import {
    useAvailableToBorrow,
    useLoader,
    useGlobalUpdate,
    useLiquidationRatio,
    useBorrowAPR,
    useSuAPR,
    useTotalDebts,
} from "hooks";
import { ButtonGradient, GradientBorder, GradientHref } from "ui-kit";
import { TokenInput } from "components/TokenInput";
import { DataSliderContainer } from "components/DataSliderContainer";
import { InfoLine } from "components/InfoLine";
import { MaxButton } from "components/MaxButton";
import { DispatchContext, StateContext } from "reducer/constants";
import { BORROW_CURRENCY, BORROW_CURRENCY_NAME, SupportedTokensType } from "utils/currency";
import { SuManagerFactory } from "utils/api";
import { addErrorNotification, addSuccessNotification } from "utils/notification";

import "./styles.scss";

export const LoanInfo = () => {
    const { id } = useParams();
    const tokenName = id as SupportedTokensType;
    const [tokenValue, setTokenValue] = useState<number>();
    const { chainId } = useContext(StateContext);
    const dispatch = useContext(DispatchContext);
    const { totalDebtsRounded } = useTotalDebts(tokenName);
    const availableToBorrow = useAvailableToBorrow(tokenName);
    const { isLoading: isBorrowLoading, start: startBorrowLoader, stop: stopBorrowLoader } = useLoader();
    const { update } = useGlobalUpdate();
    const { liquidationRatio } = useLiquidationRatio(tokenName);
    const { borrowApr } = useBorrowAPR(tokenName);
    const { suAPR } = useSuAPR();

    const handleTokenValueChange = (newTokenValue?: number) => {
        setTokenValue(newTokenValue);
    };

    const handleMaxClick = () => {
        if (availableToBorrow) {
            setTokenValue(availableToBorrow);
        }
    };

    const handleRepay = () => {
        dispatch({ type: Actions.SetActionModalTab, payload: "repay" });
        dispatch({ type: Actions.SetIsActionModalOpened, payload: true });
    };

    const handleBorrow = async () => {
        if (tokenValue && chainId) {
            try {
                startBorrowLoader();
                await SuManagerFactory.borrow(tokenName, 0, tokenValue, 0, chainId);
                addSuccessNotification("Borrow finished successfully");
                update();
                setTokenValue(0);
                stopBorrowLoader();
            } catch (e) {
                addErrorNotification("Borrow error", e.message);
                stopBorrowLoader();
            }
        }
    };

    const hasEnoughBalance = !tokenValue || tokenValue <= availableToBorrow;
    const borrowBtnText = useMemo(() => {
        if (isBorrowLoading) {
            return "Loading...";
        }
        if (!hasEnoughBalance) {
            return "Insufficient balance";
        }
        return "BORROW";
    }, [isBorrowLoading, hasEnoughBalance]);

    return (
        <DataSliderContainer
            className="loan-info"
            title="Loan info"
            name={BORROW_CURRENCY_NAME}
            value={totalDebtsRounded ?? 0}
            token={BORROW_CURRENCY}
            buttonText="Repay"
            onClick={handleRepay}
        >
            <GradientBorder borderRadius={24}>
                <div className="loan-info__content">
                    <InfoLine
                        className="loan-info__info"
                        title={`${BORROW_CURRENCY_NAME} Rewards:`}
                        value={suAPR ? `${suAPR.toFixed(2)}% APY` : "-"}
                        color="green"
                    />
                    <TokenInput
                        className="loan-info__content__input"
                        tokenName={BORROW_CURRENCY}
                        onValueChange={handleTokenValueChange}
                        isTokenFixed
                        showBalance={false}
                        value={tokenValue}
                        headerContent={
                            <div className="loan-info__content__input__available">
                                <GradientHref>
                                    Available to borrow: ${Math.max(availableToBorrow, 0).toLocaleString()}
                                </GradientHref>
                                <MaxButton onClick={handleMaxClick} />
                            </div>
                        }
                    />
                    <div className="loan-info__content__settings">
                        <InfoLine
                            size="s"
                            title="Liquidation LTV:"
                            value={liquidationRatio ? `${liquidationRatio} %` : "-"}
                        />
                        <InfoLine size="s" title="Borrow interest:" value={borrowApr ? `${borrowApr}% APY` : "-"} />
                    </div>
                    <ButtonGradient
                        className="loan-info__button"
                        onClick={handleBorrow}
                        disabled={!tokenValue || !hasEnoughBalance || isBorrowLoading}
                        loading={isBorrowLoading}
                    >
                        {borrowBtnText}
                    </ButtonGradient>
                </div>
            </GradientBorder>
        </DataSliderContainer>
    );
};
