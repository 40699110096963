import React from "react";

import { CollateralChart } from "./supportComponents/CollateralChart";
import { TransactionsInfo } from "./supportComponents/TransactionsInfo";
import { OverviewChart } from "./supportComponents/OverviewChart";

import "./styles.scss";

export const PageAnalytics = () => {
    return (
        <div className="analytics">
            <div className="analytics__line">
                <OverviewChart />
                <TransactionsInfo />
            </div>
            <CollateralChart />
        </div>
    );
};
