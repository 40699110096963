import { useCallback, useContext, useEffect, useState } from "react";
import BigNumber from "bignumber.js";

import { StateContext } from "reducer/constants";
import { SuOracleAggregatorFactory } from "utils/api";
import { getAddress, getDecimals, SupportedTokensType } from "utils/currency";
import { getHRPriceFiat } from "utils/bigNumber";

export const usePrice = (currency: SupportedTokensType) => {
    const { currentAddress, chainId } = useContext(StateContext);
    const [price, setPrice] = useState(new BigNumber(0));
    const tokenAddress = getAddress(currency, chainId);
    const decimals = getDecimals(currency, chainId);

    const updatePrice = useCallback(async () => {
        if (currentAddress) {
            setPrice(new BigNumber(0));
            const newPrice = await SuOracleAggregatorFactory.getFiatPrice1e18(tokenAddress);
            if (newPrice !== undefined) {
                setPrice(newPrice);
            }
        }
    }, [currentAddress, tokenAddress]);

    useEffect(() => {
        updatePrice();
    }, [updatePrice]);

    return {
        tokenPriceE18: price,
        tokenPrice: decimals ? getHRPriceFiat(1, price, decimals) : 0,
    };
};
