import React, { useContext, useEffect, useState } from "react";
import { Table } from "rsuite";
import Web3 from "web3";

import { StateContext } from "reducer/constants";
import SuOracleAggregator from "submodule-contract-artifacts/goerli/SuOracleAggregator.json";
import { getEtherscanAddress, getIdByNetworkName, NETWORK } from "utils/network";
import { getDecimals, getName, getSupportedTokens, SupportedTokensType } from "utils/currency";
import { TokenType } from "utils/types";
import { contracts, SuManagerFactory, SuOracleAggregatorFactory, SuVaultFactory } from "utils/api";
import { toHRNumber } from "utils/bigNumber";
import { allContracts, ContractType } from "utils/contracts";

import "./styles.scss";

const { Column, HeaderCell, Cell } = Table;

const CHAIN_ID = getIdByNetworkName(NETWORK.goerli);

type Props = {
    web3: Web3;
};

export const PageInternalStats = ({ web3 }: Props) => {
    const { isMounted } = useContext(StateContext);
    const [oracleData, setOracleData] = useState<any[]>([]);
    const [assetData, setAssetData] = useState<any[]>([]);
    const [cdpData, setCdpData] = useState<any[]>([]);
    const [contractData, setContractData] = useState<any[]>([]);

    const getAssetInformation = async (asset: TokenType) => {
        const tokenDebtLimit = await SuVaultFactory.getTokenDebtsLimit(asset.address);
        const protocolStabilityFee = await SuVaultFactory.getProtocolStabilityFee(asset.address);
        const tokenDebts = await SuVaultFactory.getTokenDebts(asset.address);

        setAssetData((oldAssetData) =>
            [
                ...oldAssetData,
                {
                    name: asset.symbol,
                    address: asset.address,
                    tokenDebtLimit: toHRNumber(tokenDebtLimit, 18),
                    protocolStabilityFee: toHRNumber(protocolStabilityFee, 18),
                    tokenDebts: toHRNumber(tokenDebts, 18),
                },
            ].sort((a, b) => a.name.localeCompare(b.name))
        );
    };

    const getOracleInformation = async (asset: TokenType) => {
        const price = await SuOracleAggregatorFactory.getFiatPrice1e18(asset.address);
        const liquidationPrice = await SuManagerFactory.getLiquidationPrice(
            getName(asset.address, CHAIN_ID) as SupportedTokensType,
            CHAIN_ID
        );

        setOracleData((oldAssetData) =>
            [
                ...oldAssetData,
                {
                    name: asset.symbol,
                    address: asset.address,
                    price: price?.toString(10),
                    liquidationPrice: liquidationPrice?.toString(10),
                },
            ].sort((a, b) => a.name.localeCompare(b.name))
        );
    };

    const getCDPInformation = async (asset: TokenType) => {
        const joinEvents = await contracts.SuManagerContract?.getPastEvents("Join", {
            filter: { asset: asset.address },
            fromBlock: 0,
            toBlock: "latest",
        });
        const users = joinEvents ? Array.from(new Set(joinEvents.map((v) => v.returnValues.owner))) : [];
        const decimals = getDecimals(asset.symbol as SupportedTokensType, CHAIN_ID);
        const liquidationRatio = await SuManagerFactory.getLiquidationRatio(asset.address);

        for (const user of users) {
            const deposit = await SuVaultFactory.getDeposited(asset.address, user);
            const debt = await SuVaultFactory.getDebt(asset.address, user);
            const fees = await SuVaultFactory.calculateFeeE18(asset.address, user);
            const ltv = await SuManagerFactory.getLTVE18(
                getName(asset.address, CHAIN_ID) as SupportedTokensType,
                CHAIN_ID,
                user
            );

            setCdpData((oldAssetData) =>
                [
                    ...oldAssetData,
                    {
                        asset: asset.symbol,
                        assetAddress: asset.address,
                        user,
                        deposit: deposit ? toHRNumber(deposit, decimals ?? 18) : 0,
                        debt: debt ? toHRNumber(debt, 18) : 0,
                        fees: fees ? toHRNumber(fees, 18) : 0,
                        ltv: toHRNumber(ltv.multipliedBy(100), 18),
                        liquidationRatio: liquidationRatio ? toHRNumber(liquidationRatio.multipliedBy(100), 18) : 0,
                    },
                ].sort((a, b) => a.asset.localeCompare(b.asset))
            );
        }
    };

    const getContractInfo = async ([name, contract]: [string, ContractType]) => {
        const implementationHex = await web3.eth.getStorageAt(
            contract.address,
            "0x360894a13ba1a3210667c828492db98dca3e2076cc3735a920a3ca505d382bbc"
        );

        setContractData((oldData) => [
            ...oldData,
            {
                name,
                address: contract.address,
                implementation: web3.eth.abi.decodeParameter("address", implementationHex),
            },
        ]);
    };

    useEffect(() => {
        if (isMounted && !assetData.length && !cdpData.length && !contractData.length) {
            Promise.all(Object.entries(allContracts).map(getContractInfo));
            const assets = getSupportedTokens(CHAIN_ID);
            Promise.all(assets.map(getOracleInformation));
            Promise.all(assets.map(getAssetInformation));
            Promise.all(assets.map(getCDPInformation));
        }
    }, [isMounted]);

    return (
        <div className="internal-stats">
            <div className="internal-stats__section">
                <div className="internal-stats__header">List of contracts</div>
                <Table className="internal-stats__table" width={1000} data={contractData} autoHeight hover>
                    <Column width={200}>
                        <HeaderCell>Name</HeaderCell>
                        <Cell dataKey="name" />
                    </Column>
                    <Column width={400}>
                        <HeaderCell>Proxy address</HeaderCell>
                        <Cell>
                            {(rowData) => (
                                <a href={getEtherscanAddress(rowData.address)} target="_blank" rel="noreferrer">
                                    {rowData.address}
                                </a>
                            )}
                        </Cell>
                    </Column>
                    <Column width={400}>
                        <HeaderCell>Implementation</HeaderCell>
                        <Cell>
                            {(rowData) => (
                                <a href={getEtherscanAddress(rowData.implementation)} target="_blank" rel="noreferrer">
                                    {rowData.implementation}
                                </a>
                            )}
                        </Cell>
                    </Column>
                </Table>
            </div>
            <div className="internal-stats__section">
                <div className="internal-stats__header">List of prices {SuOracleAggregator.address}</div>
                <Table className="internal-stats__table" width={900} data={oracleData} autoHeight hover>
                    <Column width={100}>
                        <HeaderCell>Name</HeaderCell>
                        <Cell>
                            {(rowData) => (
                                <a href={getEtherscanAddress(rowData.address)} target="_blank" rel="noreferrer">
                                    {rowData.name}
                                </a>
                            )}
                        </Cell>
                    </Column>
                    {["price", "liquidationPrice"].map((key) => (
                        <Column width={400}>
                            <HeaderCell>{key}</HeaderCell>
                            <Cell dataKey={key} />
                        </Column>
                    ))}
                </Table>
            </div>
            <div className="internal-stats__section">
                <div className="internal-stats__header">List of assets</div>
                <Table className="internal-stats__table" width={1000} data={assetData} autoHeight hover>
                    <Column width={100}>
                        <HeaderCell>Name</HeaderCell>
                        <Cell>
                            {(rowData) => (
                                <a href={getEtherscanAddress(rowData.address)} target="_blank" rel="noreferrer">
                                    {rowData.name}
                                </a>
                            )}
                        </Cell>
                    </Column>
                    {["tokenDebtLimit", "protocolStabilityFee", "tokenDebts"].map((key) => (
                        <Column width={300}>
                            <HeaderCell>{key}</HeaderCell>
                            <Cell dataKey={key} />
                        </Column>
                    ))}
                </Table>
            </div>
            <div className="internal-stats__section">
                <div className="internal-stats__header">List of CDP</div>
                <Table className="internal-stats__table" width={1250} data={cdpData} autoHeight hover>
                    <Column width={100}>
                        <HeaderCell>Asset</HeaderCell>
                        <Cell>
                            {(rowData) => (
                                <a href={getEtherscanAddress(rowData.assetAddress)} target="_blank" rel="noreferrer">
                                    {rowData.asset}
                                </a>
                            )}
                        </Cell>
                    </Column>
                    <Column width={400}>
                        <HeaderCell>user</HeaderCell>
                        <Cell dataKey="user" />
                    </Column>
                    {["deposit", "debt", "fees", "ltv", "liquidationRatio"].map((key) => (
                        <Column width={150}>
                            <HeaderCell>{key}</HeaderCell>
                            <Cell dataKey={key} />
                        </Column>
                    ))}
                </Table>
            </div>
        </div>
    );
};
