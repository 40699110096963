import React, { useContext, useEffect, useState } from "react";

import { StateContext } from "reducer/constants";
import { MetamaskIcon } from "ui-kit/images/icons";
import { addToMetamask, BORROW_CURRENCY, BORROW_CURRENCY_NAME, getTokenByName, getTokenIcon } from "utils/currency";
import { isFloat } from "utils/number";

import "./styles.scss";

interface Props {
    onChange: (newValue?: number) => void;
    value?: number;
    maxAvailableValue?: number;
}

export const BorrowInput = ({ onChange, value, maxAvailableValue }: Props) => {
    const { chainId } = useContext(StateContext);
    const [userDefinedValue, setUserDefinedValue] = useState<string>();

    useEffect(() => {
        // to work with "500." as with "500"
        if (value && `${value}` !== userDefinedValue && `${value}.` !== userDefinedValue) {
            setUserDefinedValue(value?.toString());
        }
    }, [value, userDefinedValue]);

    const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        if (!newValue) {
            setUserDefinedValue(undefined);
            onChange(undefined);
        } else if (isFloat(newValue)) {
            if (maxAvailableValue && +newValue > maxAvailableValue) {
                setUserDefinedValue(maxAvailableValue.toString());
                onChange(maxAvailableValue);
            } else {
                setUserDefinedValue(newValue);
                onChange(parseFloat(newValue));
            }
        }
    };

    const handleAddToMetamask = () => {
        const tokenMetadata = getTokenByName(BORROW_CURRENCY, chainId);
        if (tokenMetadata) {
            addToMetamask({
                ...tokenMetadata,
                image: "https://stableunit.org/assets/img/logo.svg",
            });
        }
    };

    return (
        <div className="borrow-input">
            <div className="borrow-input__token">
                <div className="borrow-input__token__icon">{getTokenIcon(BORROW_CURRENCY)}</div>
                <div className="borrow-input__token__name">{BORROW_CURRENCY_NAME}</div>
                <div className="borrow-input__token__add" onClick={handleAddToMetamask}>
                    <MetamaskIcon />
                </div>
            </div>
            <input
                className="borrow-input__input"
                value={userDefinedValue}
                onChange={handleValueChange}
                placeholder="0"
            />
        </div>
    );
};
