import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useDevice, useGlobalUpdate, usePrice, useLoader, useInitialCollateralRatio } from "hooks";
import { ButtonGradient, ButtonGray } from "ui-kit";
import BigNumber from "bignumber.js";
import { TokenInput } from "components/TokenInput";
import { TokenSlider } from "components/TokenSlider";
import { useEnoughAllowance } from "hooks/parameters/useEnoughAllowance";
import { StateContext } from "reducer/constants";
import { addErrorNotification, addSuccessNotification } from "utils/notification";
import { CommonFactory, SuManagerFactory } from "utils/api";
import { BORROW_CURRENCY_NAME, getAddress, getDecimals, isTokenInGroup, SupportedTokensType } from "utils/currency";
import { boosterLockupOptions } from "utils/lockup";
import { fromHRNumber } from "utils/bigNumber";

import { DepositBooster } from "../DepositBooster";
import { BorrowInput } from "../BorrowInput";
import { BorrowInfo } from "../BorrowInfo";
import { BoostModal } from "../BoostModal";

import "./styles.scss";

interface Props {
    onConnect: () => void;
}

export const PageBorrowContent = ({ onConnect }: Props) => {
    const { chainId } = useContext(StateContext);
    const history = useHistory();
    const { isMobile } = useDevice();
    const [tokenName, setTokenName] = useState<SupportedTokensType>("WETH");
    const [tokenValue, setTokenValue] = useState<number>();
    const [borrowValue, setBorrowValue] = useState<number>();
    const { isEnoughAllowance, setIsEnoughAllowance } = useEnoughAllowance(tokenName);
    const [boosterLockupValue, setBoosterLockupValue] = useState(boosterLockupOptions[boosterLockupOptions.length - 1]);
    const [isBorrowInfoVisible, setIsBorrowInfoVisible] = useState(false);
    const [isBoostModalVisible, setIsBoostModalVisible] = useState(false);
    const { isLoading: isApproveLoading, start: startApproveLoader, stop: stopApproveLoader } = useLoader();
    const { isLoading: isBorrowLoading, start: startBorrowLoader, stop: stopBorrowLoader } = useLoader();
    const { update } = useGlobalUpdate();

    const { tokenPrice } = usePrice(tokenName);
    const { initialCollateralRatio } = useInitialCollateralRatio(tokenName);

    const handleTokenNameChange = (newTokenName: SupportedTokensType) => {
        setTokenName(newTokenName);
    };

    const handleTokenValueChange = (newTokenValue?: number) => {
        setTokenValue(newTokenValue);
    };

    const handleBorrowValueChange = (newBorrowValue?: number) => {
        setBorrowValue(newBorrowValue);
    };

    const handleBoosterLockupValueChange = (id: number) => {
        const newValue = boosterLockupOptions.find((v) => v.id === id);
        if (newValue) {
            setBoosterLockupValue(newValue);
        }
    };

    const handleToggleBorrowInfo = () => {
        setIsBorrowInfoVisible((v) => !v);
    };

    const handleChooseYieldBoost = (name: SupportedTokensType) => {
        handleTokenNameChange(name);
        setIsBoostModalVisible(false);
    };

    const handleBorrow = async () => {
        if (tokenValue && borrowValue && chainId) {
            try {
                startBorrowLoader();
                await SuManagerFactory.borrow(tokenName, tokenValue, borrowValue, boosterLockupValue.id, chainId);
                addSuccessNotification("Borrow finished successfully");
                stopBorrowLoader();
                update();
                history.push(`/loan/${tokenName}`);
            } catch (e) {
                addErrorNotification("Borrow error", e.message);
                stopBorrowLoader();
            }
        }
    };

    const handleApprove = async () => {
        if (chainId) {
            try {
                startApproveLoader();
                await CommonFactory.approve(getAddress(tokenName, chainId));
                addSuccessNotification("Approve finished successfully");
                setIsEnoughAllowance(true);
                stopApproveLoader();

                await handleBorrow();
            } catch (e) {
                stopApproveLoader();
                addErrorNotification("Approve error", e.message);
            }
        }
    };

    useEffect(() => {
        setBorrowValue(tokenPrice && tokenValue ? (tokenPrice * tokenValue) / 2 : 0);
    }, [tokenValue, tokenPrice]);

    const maxBorrowValue = tokenPrice * (tokenValue ?? 0);
    const maxAvailableBorrowValue = (initialCollateralRatio * maxBorrowValue) / 100;

    const renderButton = () => {
        if (!chainId) {
            return (
                <ButtonGradient className="borrow__button" onClick={onConnect}>
                    Connect wallet
                </ButtonGradient>
            );
        }
        if (isEnoughAllowance) {
            const ltv = (borrowValue ?? 0) / maxBorrowValue;
            if (ltv > initialCollateralRatio / 100) {
                return (
                    <ButtonGradient className="borrow__button" disabled>
                        LTV is very high
                    </ButtonGradient>
                );
            }

            return (
                <ButtonGradient
                    className="borrow__button"
                    onClick={handleBorrow}
                    loading={isBorrowLoading}
                    disabled={
                        !borrowValue ||
                        !tokenValue ||
                        borrowValue > maxBorrowValue ||
                        borrowValue < 0 ||
                        isBorrowLoading
                    }
                >
                    {isBorrowLoading ? "Borrow loading..." : "Borrow"}
                </ButtonGradient>
            );
        }

        return (
            <ButtonGradient
                className="borrow__button"
                loading={isApproveLoading}
                onClick={handleApprove}
                disabled={isApproveLoading}
            >
                {isApproveLoading ? "Approve loading..." : "Approve"}
            </ButtonGradient>
        );
    };

    return (
        <div className="borrow">
            {!isMobile && <div className="borrow__title">Borrow {BORROW_CURRENCY_NAME}</div>}
            <ButtonGray
                className="borrow__boost"
                onClick={() => setIsBoostModalVisible(true)}
                disabled={!isTokenInGroup(tokenName, chainId)}
            >
                Yield boost
            </ButtonGray>
            <TokenInput
                tokenName={tokenName}
                onTokenChange={handleTokenNameChange}
                onValueChange={handleTokenValueChange}
                value={tokenValue}
            />

            <BorrowInput
                value={borrowValue}
                maxAvailableValue={maxAvailableBorrowValue}
                onChange={handleBorrowValueChange}
            />
            <TokenSlider
                className="borrow__token-slider"
                min={0}
                max={maxBorrowValue}
                maxAvailableValue={maxAvailableBorrowValue}
                value={borrowValue ?? 0}
                onChange={handleBorrowValueChange}
            />

            <DepositBooster
                values={boosterLockupOptions}
                value={boosterLockupValue}
                onChange={handleBoosterLockupValueChange}
            />

            <BorrowInfo
                boosterLockupValue={boosterLockupValue}
                tokenName={tokenName}
                visible={isBorrowInfoVisible}
                onToggle={handleToggleBorrowInfo}
                additionalCollateralAmount={
                    tokenValue ? fromHRNumber(tokenValue, getDecimals(tokenName, chainId) ?? 18) : BigNumber(0)
                }
                additionalStablecoinAmount={borrowValue ? fromHRNumber(borrowValue, 18) : BigNumber(0)}
            />

            {renderButton()}

            <BoostModal
                visible={isBoostModalVisible}
                onClose={() => setIsBoostModalVisible(false)}
                tokenName={tokenName}
                onStake={handleChooseYieldBoost}
            />
        </div>
    );
};
