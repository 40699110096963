import React from "react";
import { useHistory, useParams } from "react-router-dom";

import { useAvailableToBorrow } from "hooks";
import { GradientBorder, Button, GradientHref } from "ui-kit";
import { SupportedTokensType } from "utils/currency";

import "./styles.scss";

export const EarnMore = () => {
    const history = useHistory();
    const { id } = useParams();
    const availableToBorrow = useAvailableToBorrow(id as SupportedTokensType);

    const handleBorrow = () => {
        history.push(`/loan/${id}`);
    };

    return (
        <div className="earn-more-container">
            <div className="earn-settings__title">Earn more</div>
            <GradientBorder className="earn-settings__gradient-border" borderRadius={24}>
                <div className="earn-more">
                    <div className="earn-more__disclaimer">
                        <div className="earn-more__disclaimer__text">
                            Earn additional <span>APY</span> in stablecoins
                        </div>
                    </div>
                    <div className="earn-more__info">
                        <GradientHref>
                            <div className="earn-more__info__title">Available to borrow:</div>
                            <div className="earn-more__info__value">
                                ${Math.max(availableToBorrow, 0).toLocaleString()}
                            </div>
                        </GradientHref>
                        <Button width={88} padding="7px 0" onClick={handleBorrow}>
                            Borrow
                        </Button>
                    </div>
                </div>
            </GradientBorder>
        </div>
    );
};
