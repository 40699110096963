import React, { useContext, useMemo, useState } from "react";

import { useGlobalUpdate, useLoader, useTotalDebts } from "hooks";
import { ButtonGradient, GradientHref } from "ui-kit";
import BigNumber from "bignumber.js";
import { useEnoughAllowance } from "hooks/parameters/useEnoughAllowance";
import { StateContext } from "reducer/constants";
import { BORROW_CURRENCY, getAddress, getDecimals, SupportedTokensType } from "utils/currency";
import { CommonFactory, SuManagerFactory } from "utils/api";
import { addErrorNotification, addSuccessNotification } from "utils/notification";
import { fromHRNumber, toHRNumber } from "utils/bigNumber";
import { MaxButton } from "../../../MaxButton";
import { TokenInput } from "../../../TokenInput";

import "./styles.scss";

interface RepayContentProps {
    token: SupportedTokensType;
    onClose: () => void;
}

export const RepayContent = ({ token, onClose }: RepayContentProps) => {
    const { chainId } = useContext(StateContext);
    const [value, setValue] = useState<number>();
    const [valueBN, setValueBN] = useState<BigNumber>();
    const decimals = getDecimals(token, chainId) ?? 18;
    const { totalDebtsRoundedBN, totalDebtsRounded } = useTotalDebts(token);
    const { isLoading, start: startLoader, stop: stopLoader } = useLoader();
    const { update } = useGlobalUpdate();
    const { isEnoughAllowance, setIsEnoughAllowance } = useEnoughAllowance(BORROW_CURRENCY);
    const { isLoading: isApproveLoading, start: startApproveLoader, stop: stopApproveLoader } = useLoader();

    const handleValueChange = (newValue?: number) => {
        setValue(newValue);
        setValueBN(fromHRNumber(newValue ?? 0, decimals));
    };

    const handleAction = async () => {
        if (valueBN) {
            try {
                startLoader();
                await SuManagerFactory.repay(token, valueBN, chainId);
                addSuccessNotification("Repay finished successfully");
                stopLoader();
                update();
                setValue(0);
                onClose();
            } catch (e) {
                addErrorNotification("Repay error", e.message);
                stopLoader();
            }
        }
    };

    const handleMaxClick = () => {
        if (totalDebtsRounded) {
            setValue(totalDebtsRounded);
            setValueBN(totalDebtsRoundedBN);
        }
    };

    const handleApprove = async () => {
        if (chainId) {
            try {
                startApproveLoader();
                await CommonFactory.approve(getAddress(BORROW_CURRENCY, chainId), valueBN);
                addSuccessNotification("Approve finished successfully");
                setIsEnoughAllowance(true);
                stopApproveLoader();
            } catch (e) {
                stopApproveLoader();
                addErrorNotification("Approve error", e.message);
            }
        }
    };

    const hasEnoughBalance = !value || value <= totalDebtsRounded;
    const btnText = useMemo(() => {
        if (isLoading) {
            return "Loading...";
        }
        if (!hasEnoughBalance) {
            return "Insufficient balance";
        }
        return "REPAY";
    }, [isLoading, hasEnoughBalance]);

    return (
        <div className="repay-content">
            <TokenInput
                headerContent={
                    <div className="loan-info__content__input__available">
                        <GradientHref>Debt to repay: ${totalDebtsRounded}</GradientHref>
                        <MaxButton onClick={handleMaxClick} />
                    </div>
                }
                showBalance={false}
                className="repay-content__token-input"
                tokenName={BORROW_CURRENCY}
                onValueChange={handleValueChange}
                value={value}
                isTokenFixed
            />
            {isEnoughAllowance ? (
                <ButtonGradient
                    className="repay-content__button"
                    onClick={handleAction}
                    disabled={!value || !hasEnoughBalance || isLoading}
                    loading={isLoading}
                >
                    {btnText}
                </ButtonGradient>
            ) : (
                <ButtonGradient
                    className="repay-content__button"
                    loading={isApproveLoading}
                    onClick={handleApprove}
                    disabled={isApproveLoading}
                >
                    {isApproveLoading ? "Loading..." : "Approve"}
                </ButtonGradient>
            )}
        </div>
    );
};
