import { useContext } from "react";
import BigNumber from "bignumber.js";

import { StateContext } from "reducer/constants";
import { SuVaultFactory } from "utils/api";
import { BORROW_CURRENCY, getAddress, getDecimals, SupportedTokensType } from "utils/currency";
import { toHRNumber } from "utils/bigNumber";
import { useParameter } from "./useParameter";

export const useTotalDebts = (currency: SupportedTokensType) => {
    const { chainId } = useContext(StateContext);
    const decimals = getDecimals(BORROW_CURRENCY, chainId) ?? 18;
    const tokenAddress = getAddress(currency, chainId);
    const totalDebtsBN = useParameter(
        `totalDebts ${currency}`,
        () => SuVaultFactory.getTotalDebtE18(tokenAddress),
        tokenAddress
    );
    const totalDebtsRoundedBN = totalDebtsBN?.multipliedBy(100_001).dividedBy(100_000).dp(0, BigNumber.ROUND_CEIL);

    return {
        totalDebtsBN,
        totalDebtsRoundedBN,
        totalDebtsRounded: totalDebtsRoundedBN ? toHRNumber(totalDebtsRoundedBN, decimals) : 0,
        totalDebts: totalDebtsBN ? toHRNumber(totalDebtsBN, 18) : 0,
    };
};
